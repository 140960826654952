<template>
  <div ref="jsoneditor" class="jsoneditor-box" />
</template>

<script>
import "@/assets/jsoneditor/jsoneditor.min.css";
import JSONEditor from "@/assets/jsoneditor/jsoneditor-minimalist.min";

export default {
  name: "JsonEditor",
  beforeDestroy() {
    this.jsoneditor.destroy();
    this.jsoneditor = null;
  },
  data() {
    return {
      jsoneditor: null
    };
  },
  methods: {
    getOptions() {
      const defaults = {
        escapeUnicode: false,
        history: true,
        indentation: 2,
        mode: "view",
        modes: ["code", "form", "text", "tree", "view"],
        mainMenuBar: true,
        navigationBar: true,
        search: true,
        statusBar: true,
        sortObjectKeys: false
      };

      return Object.assign({}, defaults, this.options, {
        onChange: this.onChange,
        onError: this.onError
      });
    },
    onChange() {
      try {
        const value = this.jsoneditor.get();
        this.$emit("input", value);
        // eslint-disable-next-line
      } catch (e) {}
    },
    onError(error) {
      this.$emit("error", error);
    }
  },
  mounted() {
    this.jsoneditor = new JSONEditor(
      this.$refs.jsoneditor,
      this.getOptions(),
      this.value
    );

    this.onChange();
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    value: {
      default() {
        return {};
      }
    }
  },
  watch: {
    value(value) {
      this.jsoneditor.set(value);
    }
  }
};
</script>

<style lang="css">
.jsoneditor {
  border-color: white;
}
.jsoneditor-box {
  height: 512px;
  width: 100%;
}
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected {
  background-color: #ffe082;
  color: black;
}
.jsoneditor-menu {
  background-color: #00695c;
  border-bottom: #00695c;
}
</style>
