<template>
  <v-row align="center" dense>
    <v-col cols="2" sm="1">
      <v-icon>{{ icons.iconCondition }}</v-icon>
    </v-col>
    <v-col cols="10" sm="11">
      <v-chip-group class="my-2">
        <v-chip
          label
          outlined
          v-for="(tag, index) in conditions"
          :key="index"
          :color="getTagColor(tag)"
          :text-color="getTagColor(tag)"
        >
          <strong>{{ getTagLabel(tag) }}</strong>
        </v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { getConditionColor, getConditionText } from "@/models/actor";

export default {
  name: "ConditionBlock",

  data: () => ({
    icons: IconLib
  }),
  methods: {
    getTagColor(tag) {
      return getConditionColor(tag);
    },
    getTagLabel(tag) {
      return getConditionText(tag);
    }
  },
  props: {
    conditions: { type: Array, required: true }
  }
};
</script>
