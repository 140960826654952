<template>
  <v-list id="actor-skills" dense flat v-if="hasSkills">
    <v-list-item-group color="indigo">
      <SkillBlock v-for="(skill, index) in actor.skills" :key="index" :skill="skill" />
    </v-list-item-group>
  </v-list>
  <NothingBlock v-else />
</template>

<script>
import NothingBlock from "@/blocks/NothingBlock";
import SkillBlock from "@/blocks/SkillBlock";
import { isNotEmpty } from "@/store";

export default {
  name: "ActorSkills",

  components: {
    NothingBlock,
    SkillBlock
  },
  computed: {
    hasSkills() {
      return isNotEmpty(this.actor.skills);
    }
  },
  props: {
    actor: { type: Object, required: true }
  }
};
</script>
