<template>
  <v-dialog id="monster-create" max-width="640" persistent v-model="showDialog">
    <v-form>
      <v-card>
        <v-card-title> Create&nbsp;Monster&hellip; </v-card-title>
        <v-card-text>
          <v-row align="center" dense>
            <v-col cols="8">
              <v-text-field
                autocomplete="off"
                label="Name"
                name="actorName"
                type="text"
                v-model.trim="form.name"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                autocomplete="off"
                label="Init"
                name="actorInit"
                type="text"
                v-model.trim="form.initiative"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" dense>
            <v-col cols="8">
              <v-text-field
                autocomplete="off"
                hint="description; attack bonus; damage"
                label="Melee"
                name="actorMelee"
                type="text"
                v-model.trim="form.melee"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                autocomplete="off"
                hint="normal; touch; flat-footed"
                label="AC"
                name="armorClass"
                type="text"
                v-model.trim="form.armorClass"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" dense>
            <v-col cols="8">
              <v-text-field
                autocomplete="off"
                hint="description; attack bonus; damage"
                label="Ranged"
                name="actorRanged"
                type="text"
                v-model.trim="form.ranged"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                autocomplete="off"
                label="Hit Dice"
                name="hitDice"
                type="text"
                v-model.trim="form.hitDice"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" dense>
            <v-col cols="8">
              <v-select
                label="Attitude"
                v-model.trim="form.selectedAttitude"
                :disabled="!form.selectedAttitude"
                :items="attitudes"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <SpinnerBlock
                label="Count"
                :minVal="0"
                :value="form.actorCount"
                v-on:value-change="form.actorCount = $event"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="closeDialog"> Cancel </v-btn>
          <v-spacer />
          <v-btn color="primary" type="submit" @click.stop="onSubmit">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import SpinnerBlock from "@/blocks/SpinnerBlock";
import { ActorCreateDTO, Attitudes, HOSTILE } from "@/models/actor";

export default {
  name: "MonsterCreate",

  components: {
    SpinnerBlock
  },
  computed: {
    showDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  data() {
    return {
      attitudes: Attitudes,
      icons: IconLib,
      //  form fields
      form: {
        actorCount: 1,
        armorClass: "",
        hitDice: "",
        initiative: "",
        melee: "",
        name: "",
        ranged: "",
        selectedAttitude: HOSTILE
      }
    };
  },
  methods: {
    closeDialog: function() {
      this.$emit("close-monster-create");
    },
    onReset() {
      this.form.actorCount = 1;
      this.form.armorClass = "";
      this.form.hitDice = "";
      this.form.initiative = "";
      this.form.melee = "";
      this.form.name = "";
      this.form.ranged = "";
      this.form.selectedAttitude = HOSTILE;
    },
    onSubmit() {
      const dto = new ActorCreateDTO(
        this.form.name,
        this.form.initiative,
        this.form.melee,
        this.form.armorClass,
        this.form.ranged,
        this.form.hitDice,
        this.form.actorCount,
        this.form.selectedAttitude
      );

      // console.log("MonsterCreate::onSubmit() => %o", dto);

      this.$store.dispatch("actor/create", dto);
      this.closeDialog();
    }
  },
  props: {
    visible: { type: Boolean, required: true, default: false }
  },
  watch: {
    showDialog: function() {
      if (this.visible) this.onReset();
    }
  }
};
</script>
