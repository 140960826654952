<template>
  <v-row id="val-block" no-gutters>
    <v-col class="pb-0 pt-1 text-center" cols="12">
      <span :class="styleValue">{{ formatted }}</span>
      <v-divider class="mx-3" />
    </v-col>
    <v-col class="py-0 text-center" cols="12">
      <span class="caption">{{ label }}</span>
    </v-col>
  </v-row>
</template>

<script>
import { formatInt } from "@/store";

export default {
  name: "ValBlock",
  computed: {
    formatted() {
      return this.showZero || this.value != 0
        ? formatInt(this.value, this.showCommas, this.showSign)
        : "\u2014"; // &mdash;
    },
    styleValue() {
      if (this.muted) return "body-1";

      return parseInt(this.value) < 0
        ? "font-weight-bold red--text subtitle-1"
        : "font-weight-bold subtitle-1";
    }
  },
  props: {
    label: { type: String, required: false, default: "--" },
    muted: { type: Boolean, required: false, default: false },
    showCommas: { type: Boolean, required: false, default: false },
    showSign: { type: Boolean, required: false, default: false },
    showZero: { type: Boolean, required: false, default: true },
    value: { required: true }
  }
};
</script>
