import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";
import { Ripple } from "vuetify/lib/directives";

import vuetify from "@/plugins/vuetify";
import store from "@/store";

import App from "@/App.vue";

Vue.config.productionTip = false;

Vue.use(VueClipboard);
Vue.use(VueCookies);
Vue.use(Vuelidate);

new Vue({
  store,
  vuetify,
  directives: {
    Ripple // See https://github.com/DoritoBandito/vuetify-upload-button/issues/14
  },
  render: h => h(App)
}).$mount("#app");
