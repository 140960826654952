<template>
  <v-list-item id="class-block" class="pa-n2">
    <v-col class="pa-0" cols="7" sm="8">
      <span class="subtitle-1" :class="{ 'is-favored': classInfo.favored }">
        {{ classInfo.title }}
      </span>
    </v-col>
    <v-col class="pa-0" cols="3">
      <ValBlock label="Levels" :showSign="false" :value="classInfo.levels" />
    </v-col>
    <v-col class="pa-0" cols="2" sm="1" v-if="url">
      <v-btn color="info" icon small target="_blank" :href="url">
        <v-icon class="mdi-18px">{{ icons.iconWebLink }}</v-icon>
      </v-btn>
    </v-col>
  </v-list-item>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { linkToSRD } from "@/services";
import { isValidStr } from "@/store";
import ValBlock from "@/blocks/ValBlock";

export default {
  name: "ClassBlock",
  components: {
    ValBlock,
  },
  computed: {
    url() {
      return isValidStr(this.classInfo.url)
        ? linkToSRD(this.classInfo.url)
        : undefined;
    },
  },
  data: () => ({
    icons: IconLib,
  }),
  props: {
    classInfo: { type: Object, required: true },
  },
};
</script>

<style scoped>
.is-favored {
  font-weight: bolder;
}
</style>
