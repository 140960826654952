<template>
  <v-dialog id="dice-bag" max-width="320" persistent v-model="showDialog">
    <v-card dense flat>
      <v-card-title>
        <span class="caption">Roll d20&hellip;</span>
        <v-spacer />
        <v-btn icon small @click.stop="closeDialog">
          <v-icon>{{ icons.iconClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row align="center" dense>
          <v-col cols="6" sm="7">
            <span class="caption">{{ prettyRolls }}</span>
          </v-col>
          <v-col cols="2" sm="1">
            <v-icon color="green" v-if="lastRoll.isCritical">
              {{ icons.iconEqualBox }}
            </v-icon>
            <v-icon color="red" v-else-if="lastRoll.isFumble">
              {{ icons.iconEqualBox }}
            </v-icon>
            <v-icon v-else>
              {{ icons.iconEqual }}
            </v-icon>
          </v-col>
          <v-col class="text-center" cols="4">
            <span class="headline">
              <strong>{{ prettyResult }}</strong>
            </span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-text-field
            class="mt-1 mb-0"
            clearable
            full-width
            outlined
            v-model.trim="input"
            :error-messages="errText"
            @keydown.enter="onEqual"
          />
        </v-row>
        <!-- Row 0 -->
        <v-row dense>
          <v-col cols="3">
            <v-btn color="blue-grey lighten-4" tile @click.stop="addText('d')">
              d?
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="blue-grey lighten-4" tile @click.stop="addText('d%')">
              d%
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="amber" tile @click.stop="onBackspace">
              <v-icon>{{ icons.iconBackspace }} </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="amber" tile @click.stop="onClear">
              AC
            </v-btn>
          </v-col>
        </v-row>
        <!-- Row 1 -->
        <v-row dense>
          <v-col cols="3">
            <v-btn color="blue-grey lighten-4" tile @click.stop="addText('d4')">
              d4
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="blue-grey lighten-4" tile @click.stop="addText('d6')">
              d6
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="blue-grey lighten-4" tile @click.stop="addText('d8')">
              d8
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="teal lighten-3" tile @click.stop="addText('/')">
              <v-icon>{{ icons.iconDivision }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- Row 2 -->
        <v-row dense>
          <v-col cols="3">
            <v-btn
              color="blue-grey lighten-4"
              tile
              @click.stop="addText('d10')"
            >
              d10
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="blue-grey lighten-4"
              tile
              @click.stop="addText('d12')"
            >
              d12
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="blue-grey lighten-4"
              tile
              @click.stop="addText('d20')"
            >
              d20
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="teal lighten-3" tile @click.stop="addText('*')">
              <v-icon>{{ icons.iconMultiply }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- Row 3 -->
        <v-row dense>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('7')">
              7
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('8')">
              8
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('9')">
              9
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="teal lighten-3" tile @click.stop="addText('-')">
              <v-icon>{{ icons.iconMinus }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- Row 4 -->
        <v-row dense>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('4')">
              4
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('5')">
              5
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('6')">
              6
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="teal lighten-3" tile @click.stop="addText('+')">
              <v-icon>{{ icons.iconPlus }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- Row 5 -->
        <v-row dense>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('1')">
              1
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('2')">
              2
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('3')">
              3
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="teal" dark tile @click.stop="onHistory">
              <v-icon>{{ icons.iconRepeat }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- Row 6 -->
        <v-row dense>
          <v-col cols="3">
            <v-btn color="blue-grey lighten-4" tile @click.stop="addText('(')">
              (
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="grey lighten-3" tile @click.stop="addText('0')">
              0
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="blue-grey lighten-4" tile @click.stop="addText(')')">
              )
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="teal" dark tile @click.stop="onEqual">
              <v-icon>{{ icons.iconEqual }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { formatInt, isNotEmpty, isValidStr } from "@/store";

export default {
  name: "DiceBag",

  computed: {
    prettyResult() {
      return formatInt(this.result, true);
    },
    prettyRolls() {
      return isNotEmpty(this.rolls) ? this.rolls.join(" ") : this.input;
    },
    showDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  data() {
    return {
      errText: "",
      icons: IconLib,
      input: "",
      lastRoll: {},
      previous: [],
      previousIndex: 0,
      result: 0,
      rolls: []
    };
  },
  methods: {
    addText(str) {
      if (isValidStr(str)) this.input = (this.input || "") + str.trim();
    },
    closeDialog() {
      this.$store.dispatch("toggleDiceBag");
    },
    onBackspace() {
      if (isValidStr(this.input)) {
        this.input = this.input.slice(0, -1);
      }
    },
    onClear() {
      // this.previous = [];
      this.previousIndex = 0;
      this.input = "";
      this.lastRoll = {};
      this.result = 0;
      this.rolls = [];
    },
    onEqual() {
      const roll = this.$store.state.diceBag.roll(this.input);

      if (roll.isValid) {
        this.errText = "";
        this.lastRoll = roll;
        this.result = roll.total;
        this.rolls = roll.rolls;
        //  limit result to only 2 decimal places
        if (this.result != Math.trunc(this.result))
          this.result = this.result.toFixed(2);

        // console.log("DiceBag rolled %o (%o)", this.result, this.rolls);

        if (this.previous.indexOf(this.input) < 0) {
          // hack to limit the previous array to 16 items.
          this.previous = [this.input, ...this.previous.slice(0, 15)];
          // console.log("Dice Bag history: %o", this.previous);
        }
      } else {
        this.errText = roll.errorMessage;
      }
    },
    onHistory() {
      if (isNotEmpty(this.previous)) {
        this.previousIndex++;
        if (this.previousIndex >= this.previous.length) this.previousIndex = 0;

        this.input = this.previous[this.previousIndex];
      }
    }
  },
  props: {
    visible: { type: Boolean, required: true, default: false }
  },
  watch: {
    visible: function() {
      this.showDialog = this.visible;
    }
  }
};
</script>

<style scoped>
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 75%;
}
.v-btn {
  font-weight: bold;
  text-transform: none !important;
}
</style>
