<template>
  <v-container
    id="actor-list"
    class="pa-2"
    fluid
    v-if="actors"
    v-scroll="onScroll"
  >
    <v-tabs color="brown darken-1" grow v-model="tab">
      <v-tab>
        <v-icon>{{ icons.iconHeroes }}</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>{{ icons.iconFoes }}</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>{{ icons.iconCorpses }}</v-icon>
      </v-tab>
      <v-tab-item>
        <v-toolbar class="elevation-0" dense>
          <v-btn class="mr-2" outlined small @click.stop="onPartyInit">
            <v-icon class="mdi-18px mr-2">{{ icons.iconInit }}</v-icon>
            Init
          </v-btn>
          <v-btn class="mr-2" outlined small @click.stop="onHealAll">
            <v-icon class="mdi-18px mr-2">{{ icons.iconHeal }}</v-icon>
            Heal&nbsp;All
          </v-btn>
        </v-toolbar>
        <v-row v-if="friends.length">
          <v-col cols="12" v-for="actor in friends" :key="actor.id">
            <ActorCard :actor="actor" :isDM="true" :showHealth="true" />
          </v-col>
        </v-row>
        <NothingBlock v-else />
      </v-tab-item>
      <v-tab-item>
        <v-toolbar class="elevation-0" dense>
          <v-btn outlined small @click.stop="onMonsterSummon">
            <v-icon class="mdi-18px mr-2">{{ icons.iconSummonMonster }}</v-icon>
            Summon
          </v-btn>
          <v-btn class="ml-2" outlined small @click.stop="onMonsterCreate">
            <v-icon class="mdi-18px mr-2">{{ icons.iconCreateMonster }}</v-icon>
            Create
          </v-btn>
        </v-toolbar>
        <v-row v-if="foes.length">
          <v-col cols="12" v-for="actor in foes" :key="actor.id">
            <ActorCard :actor="actor" :isDM="true" :showHealth="true" />
          </v-col>
        </v-row>
        <NothingBlock class="pt-4" v-else />
      </v-tab-item>
      <v-tab-item>
        <v-toolbar class="elevation-0" dense v-if="corpses.length">
          <ValBlock label="Total XP" :showCommas="true" :value="totalXP" />
          <v-spacer />
          <v-btn color="error" outlined small @click.stop="onRemoveAll">
            <v-icon class="mr-2">{{ icons.iconRemoveAll }}</v-icon>
            Discard All
          </v-btn>
        </v-toolbar>
        <v-row v-if="corpses.length">
          <v-col cols="12" v-for="actor in corpses" :key="actor.id">
            <ActorCard :actor="actor" :isDM="true" />
          </v-col>
        </v-row>
        <NothingBlock class="pt-4" v-else />
      </v-tab-item>
    </v-tabs>
    <v-fab-transition>
      <v-btn
        color="purple"
        fab
        fixed
        bottom
        center
        dark
        small
        v-show="showFab"
        @click="$vuetify.goTo('#actor-list', { duration: 500, offset: 0 })"
      >
        <v-icon>{{ icons.iconToTop }}</v-icon>
      </v-btn>
    </v-fab-transition>

    <Confirm ref="confirm"></Confirm>
    <MonsterCreate
      :visible="showMonsterCreate"
      v-on:close-monster-create="showMonsterCreate = false"
    />
    <MonsterSummon
      :visible="showMonsterSummon"
      v-on:close-monster-summon="showMonsterSummon = false"
    />
    <PartyInit
      :actors="playerActors"
      :visible="showPartyInit"
      v-on:close-party-init="showPartyInit = false"
    />
  </v-container>
  <v-row class="pt-4" v-else>
    <NothingBlock />
  </v-row>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { isHostileActor } from "@/models/actor";
import { linkToSRD } from "@/services";
import { isValidStr } from "@/store";

import NothingBlock from "@/blocks/NothingBlock";
import ValBlock from "@/blocks/ValBlock";
import ActorCard from "@/components/ActorCard";
import MonsterCreate from "@/dialogs/MonsterCreate";
import MonsterSummon from "@/dialogs/MonsterSummon";
import PartyInit from "@/dialogs/PartyInit";
import Confirm from "@/plugins/Confirm";

export default {
  name: "ActorList",
  components: {
    ActorCard,
    Confirm,
    NothingBlock,
    ValBlock,
    MonsterCreate,
    MonsterSummon,
    PartyInit,
  },
  computed: {
    actors() {
      return this.$store.getters["actor/all"];
    },
    corpses() {
      return this.actorsDead;
    },
    foes() {
      return this.actorsFoes;
    },
    friends() {
      return this.actorsFriends;
    },
    playerActors() {
      return this.actors.filter((a) => isValidStr(a.idUser));
    },
    showFab() {
      return this.offsetTop > 60;
    },
    totalXP() {
      var total = 0;

      this.corpses.forEach((a) => {
        total += a.getValue("xpVal");
      });

      return total;
    },
  },
  data() {
    return {
      actorsDead: [],
      actorsFoes: [],
      actorsFriends: [],
      icons: IconLib,
      linkBestiary: linkToSRD("/srd/indices/bestiary.html"),
      offsetTop: 0,
      showMonsterCreate: false,
      showMonsterSummon: false,
      showPartyInit: false,
      tab: null,
    };
  },
  methods: {
    load() {
      this.actorsDead = [];
      this.actorsFoes = [];
      this.actorsFriends = [];
      this.actors.forEach((a) => {
        if (a.hpNow > -10) {
          if (isHostileActor(a) || isValidStr(a.mapStrings["idTemplate"])) {
            this.actorsFoes.push(a);
          } else {
            this.actorsFriends.push(a);
          }
        } else this.actorsDead.push(a);
      });
    },
    onHealAll() {
      this.$store.dispatch("actor/healAll");
    },
    onMonsterCreate() {
      this.showMonsterCreate = true;
    },
    onMonsterSummon() {
      this.showMonsterSummon = true;
    },
    onPartyInit() {
      this.showPartyInit = true;
    },
    onRemoveAll() {
      this.$refs.confirm
        .open("Remove All", "Remove all dead actors?", { color: "error" })
        .then((confirmed) => {
          if (confirmed) this.$store.dispatch("actor/removeAll");
        });
    },
    onScroll() {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
  },
  watch: {
    actors: function () {
      this.load();
    },
  },
};
</script>
