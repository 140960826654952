<template>
  <v-col id="actor-spells" class="pa-1" cols="12">
    <v-row align="center" class="pb-3" dense>
      <v-col cols="4">
        <ValBlock label="Caster Level" :value="school.casterLevel" />
      </v-col>
      <v-col cols="2" offset="6">
        <v-btn color="info" fab small target="_blank" v-show="url" :href="url">
          <v-icon>{{ icons.iconSpellsLink }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      dense
      class="py-3"
      v-for="(maxCount, index) in school.spellsPerDay"
      :key="index"
    >
      <v-col cols="3" sm="2">
        <span class="subtitle-2">{{ label(index) }}</span>
      </v-col>
      <v-col cols="9" sm="10">
        <v-rating
          background-color="brown lighten-4"
          clearable
          dense
          :color="scrollColor"
          :empty-icon="icons.iconSpellSlotEmpty"
          :full-icon="icons.iconSpellSlot"
          v-if="maxCount > 0"
          v-model="school.spellsAvailable[index]"
          :length="maxCount"
          :readonly="!isDM"
        />
        <v-icon color="brown" v-else>{{ icons.iconUnlimited }}</v-icon>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import ValBlock from "@/blocks/ValBlock";
import { linkToSRD } from "@/services";
import { isNotEmpty, isValidStr } from "@/store";

export default {
  name: "ActorSpells",

  components: {
    ValBlock,
  },
  computed: {
    school() {
      if (isNotEmpty(this.spells)) {
        var index = this.spells.findIndex(
          (s) => s.spellClass == this.spellClass
        );
        if (index >= 0) return this.spells[index];
      }

      return undefined;
    },
    scrollColor() {
      return this.$vuetify.theme.isDark ? "brown lighten-3" : "brown";
    },
    spells() {
      return this.actor ? this.actor.spells : [];
    },
    url() {
      return isValidStr(this.school.url)
        ? linkToSRD(this.school.url)
        : undefined;
    },
  },
  data: () => ({
    icons: IconLib,
  }),
  methods: {
    label(level) {
      if (level > 0) return `Level ${level}`;
      return this.school.divine ? "Orisons" : "Cantrips";
    },
  },
  props: {
    actor: { type: Object, required: true },
    isDM: { type: Boolean, required: false, default: false },
    spellClass: { type: String, required: true },
  },
};
</script>
