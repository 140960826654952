<template>
  <v-col class="nothing-block pa-0 text-center" cols="12">
    <span class="caption font-italic grey--text">
      This page intentionally left blank.
    </span>
  </v-col>
</template>

<script>
export default {
  name: "NothingBlock"
};
</script>
