<template>
  <v-card class="familiar-card pa-0" flat v-if="familiar">
    <v-card-subtitle v-if="racialType">
      <span class="subtitle-1">{{ racialType }}</span>
    </v-card-subtitle>
    <v-card-text>
      <HealthBlock
        :hpNow="familiar.hpNow"
        :hpMax="familiar.hpMax"
        :showValues="true"
      />
      <ConditionBlock :conditions="familiar.conditions" v-if="hasConditions" />
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconInit }}</v-icon>
        </v-col>
        <v-col cols="3">
          <ValBlock
            label="Init"
            :value="familiar.getValue('initiative')"
            :showSign="true"
          />
        </v-col>
        <v-col cols="7" sm="8" v-if="speed">
          <ValBlock label="Speed" :muted="true" :value="speed" />
        </v-col>
      </v-row>
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconArmorClass }}</v-icon>
        </v-col>
        <v-col cols="3">
          <ValBlock label="AC" :value="familiar.getValue('acNormal')" />
        </v-col>
        <v-col cols="4">
          <ValBlock
            label="Flatfooted"
            :muted="true"
            :value="familiar.getValue('acFlatFooted')"
          />
        </v-col>
        <v-col cols="3">
          <ValBlock
            label="Touch"
            :muted="true"
            :value="familiar.getValue('acTouch')"
          />
        </v-col>
      </v-row>
      <ActionBlock
        v-if="readiedAction"
        :action="readiedAction"
        :expandable="true"
      />
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconGrapple }}</v-icon>
        </v-col>
        <v-col cols="5">
          <ValBlock label="CMB" :value="familiar.getString('cmb')" />
        </v-col>
        <v-col cols="5">
          <ValBlock label="CMD" :value="familiar.getString('cmd')" />
        </v-col>
      </v-row>
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconSavingThrows }}</v-icon>
        </v-col>
        <v-col cols="3">
          <ValBlock
            label="Fortitude"
            :value="familiar.getValue('saveFortitude')"
          />
        </v-col>
        <v-col cols="3" sm="4">
          <ValBlock label="Reflex" :value="familiar.getValue('saveReflex')" />
        </v-col>
        <v-col cols="3">
          <ValBlock label="Will" :value="familiar.getValue('saveWill')" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import ActionBlock from "@/blocks/ActionBlock";
import ConditionBlock from "@/blocks/ConditionBlock";
import HealthBlock from "@/blocks/HealthBlock";
import ValBlock from "@/blocks/ValBlock";
import { isNotEmpty } from "@/store";

export default {
  name: "FamiliarCard",

  components: {
    ActionBlock,
    ConditionBlock,
    HealthBlock,
    ValBlock,
  },
  computed: {
    hasConditions() {
      return isNotEmpty(this.familiar.conditions);
    },
    readiedAction() {
      const readyAction = this.familiar.getValue("readyAction");
      return this.familiar.actions[readyAction] || "";
    },
    racialType() {
      return this.familiar.getString("race");
    },
    speed() {
      return this.familiar.getString("speed");
    },
  },
  data() {
    return {
      icons: IconLib,
    };
  },
  props: {
    familiar: { type: Object, required: true },
    isDM: { type: Boolean, required: false, default: false },
  },
};
</script>
