<template>
  <v-list id="actor-stats" dense flat v-if="hasStats">
    <v-list-item-group class="pa-1" color="indigo">
      <StatBlock v-for="(stat, index) in actor.stats" :key="index" :stat="stat" />
    </v-list-item-group>
  </v-list>
  <NothingBlock v-else />
</template>

<script>
import NothingBlock from "@/blocks/NothingBlock";
import StatBlock from "@/blocks/StatBlock";

export default {
  name: "ActorStats",

  components: {
    NothingBlock,
    StatBlock
  },
  computed: {
    hasStats() {
      return this.actor.stats && this.actor.stats.length > 0;
    }
  },
  props: {
    actor: { type: Object, required: true }
  }
};
</script>
