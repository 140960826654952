<template>
  <v-row align="center" no-gutters>
    <v-col cols="1">
      <span class="caption">XP:</span>
    </v-col>
    <v-col cols="7">
      <v-progress-linear
        background-color="indigo darken-2"
        color="indigo accent-4"
        dark
        height="28"
        :value="percentage"
      >
        <template :v-slot="value">
          <strong>{{ formatted }}</strong>
        </template>
      </v-progress-linear>
    </v-col>
    <v-col cols="4">
      <ValBlock label="ECL" :value="actor.getValue('ecl')" />
    </v-col>
  </v-row>
</template>

<script>
import { formatInt } from "@/store";
import ValBlock from "@/blocks/ValBlock";

export default {
  name: "ExperienceBlock",

  components: {
    ValBlock
  },
  computed: {
    formatted() {
      return (
        formatInt(this.xpNow, true) + " of " + formatInt(this.xpGoal, true)
      );
    },
    percentage() {
      if (this.xpNow <= 0 || this.xpGoal <= 0) return 0;
      else if (this.xpNow >= this.xpGoal) return 100;
      else return Math.ceil((this.xpNow * 100) / this.xpGoal);
    },
    xpGoal() {
      return this.actor.getValue("xpGoal");
    },
    xpNow() {
      return this.actor.getValue("xpNow");
    },
    xpVal() {
      return this.actor.getValue("xpVal");
    }
  },
  props: {
    actor: { type: Object, required: true }
  }
};
</script>
