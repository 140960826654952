<template>
  <v-container id="login">
    <v-form @submit.prevent="onSubmit">
      <v-row class="pt-1" justify="center">
        <v-img
          :src="require('@/assets/logo-128x128.png')"
          class="my-1"
          contain
          height="128"
          width="128"
        ></v-img>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" offset-sm="3">
          <v-text-field
            autocomplete="off"
            label="User Name"
            name="username"
            :prepend-icon="icons.iconAccount"
            required
            type="text"
            v-model.trim="$v.username.$model"
            :error-messages="usernameErrors"
            @blur="$v.username.$touch()"
            @input="$v.username.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" offset-sm="3">
          <v-text-field
            autocomplete="off"
            label="Password"
            name="password"
            required
            v-model.trim="$v.password.$model"
            :append-icon="showPW ? icons.iconShowPassword : icons.iconHidePassword"
            :error-messages="passwordErrors"
            :prepend-icon="icons.iconPassword"
            :type="showPW ? 'text' : 'password'"
            @blur="$v.password.$touch()"
            @click:append="showPW = !showPW"
            @input="$v.password.$touch()"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn class="my-2" color="primary" type="submit">
          <span>Onwards!</span>
        </v-btn>
      </v-row>
    </v-form>
    <v-alert class="mx-2 my-3" type="error" v-if="error">
      {{ error }}
    </v-alert>
    <v-row>
      <v-col class="pt-4 text-center" cols="12">
        <span class="caption">{{ this.$store.state.version }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { required } from "vuelidate/lib/validators";
import User from "@/models/user";

export default {
  name: "Login",

  computed: {
    passwordErrors() {
      const errors = [];
      if (this.$v.password.$dirty) {
        !this.$v.password.required && errors.push("Password is required.");
      }
      return errors;
    },
    usernameErrors() {
      const errors = [];
      if (this.$v.username.$dirty) {
        !this.$v.username.required && errors.push("User name is required.");
      }
      return errors;
    }
  },
  data() {
    return {
      error: "",
      icons: IconLib,
      showPW: false,
      //  form fields
      password: "",
      username: ""
    };
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return; // stop here if form is invalid

      this.$store
        .dispatch("user/login", new User(this.username, this.password))
        .then(
          () => {
            this.error = "";
          },
          (error) => {
            console.log("E: %o", error);
            this.error = error.message && error.message.includes("Network Error")
              ? "Network Error"
              : "Login Failed";
          }
        );
    }
  },
  validations: {
    password: { required },
    username: { required }
  }
};
</script>
