<template>
  <v-row id="spinner-block" align="center" dense>
    <v-col class="text-center" cols="2">
      <v-btn
        icon
        :disabled="value <= minVal"
        v-on:click="$emit('value-change', value - 1)"
      >
        <v-icon color="red">{{ icons.iconDecrement }}</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="8">
      <ValBlock :label="labelText" :showCommas="true" :value="value" />
    </v-col>
    <v-col class="text-center" cols="2">
      <v-btn
        icon
        :disabled="value >= maxVal"
        v-on:click="$emit('value-change', value + 1)"
      >
        <v-icon color="green">{{ icons.iconIncrement }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import ValBlock from "@/blocks/ValBlock";

export default {
  name: "SpinnerBlock",

  components: {
    ValBlock
  },
  computed: {
    labelText() {
      return this.showRange ? `of ${this.maxVal}` : this.label || "";
    }
  },
  data: () => ({
    icons: IconLib
  }),
  props: {
    label: { type: String, required: false, default: "" },
    maxVal: { type: Number, required: false, default: 999999 },
    minVal: { type: Number, required: false, default: 0 },
    showRange: { type: Boolean, required: false, default: false },
    value: { type: Number, required: false, default: 0 }
  }
};
</script>
