import {
  mdiAccount,
  mdiAccountCircle,
  mdiAccountMultiple,
  mdiAccountOutline,
  mdiAccountSearchOutline,
  mdiAccountSettings,
  mdiArrowDown,
  mdiArrowUp,
  mdiBadgeAccountOutline,
  mdiChevronTripleUp,
  mdiBackspaceOutline,
  mdiBookOpenPageVariant,
  mdiCardAccountDetailsOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiClockStart,
  mdiClose,
  mdiCloseBoxMultiple,
  mdiCloseBoxOutline,
  mdiCodeJson,
  mdiCogTransferOutline,
  mdiCommentTextMultipleOutline,
  mdiContentDuplicate,
  mdiDiamondStone,
  mdiDiceD20,
  mdiDivision,
  mdiDramaMasks,
  mdiEmoticonNeutralOutline,
  mdiEqual,
  mdiEqualBox,
  mdiFingerprint,
  mdiFormTextboxPassword,
  mdiGestureDoubleTap,
  mdiGraveStone,
  mdiHeadOutline,
  mdiHeart,
  mdiHeartOutline,
  mdiHeartPulse,
  mdiHomeHeart,
  mdiInfinity,
  mdiInformationVariant,
  mdiInvertColors,
  mdiKabaddi,
  mdiLogout,
  mdiMagnify,
  mdiMathLog,
  mdiMinus,
  mdiNotebookOutline,
  mdiOpenInNew,
  mdiPaw,
  mdiPeace,
  mdiPlus,
  mdiPlusThick,
  mdiPodcast,
  mdiPower,
  mdiProgressClock,
  mdiRefresh,
  mdiSchool,
  mdiScript,
  mdiScriptOutline,
  mdiScriptText,
  mdiSearchWeb,
  mdiShieldHalfFull,
  mdiSkull,
  mdiSkullCrossbones,
  mdiSkullOutline,
  mdiStar,
  mdiSword,
  mdiSwordCross,
  mdiTarget,
  mdiEye,
  mdiEyeOff,
  mdiLock,
  mdiThumbsUpDown,
  mdiTransferDown,
  mdiTransferUp,
  mdiTreasureChest,
  mdiTrophy,
  mdiUndoVariant,
  mdiUpload,
  mdiWindowMinimize
} from "@mdi/js";

export const IconLib = (function () {
  "use strict";
  return {
    healthIcon(hp) {
      if (hp > 0) return this.iconHealth;
      return hp > -10 ? this.iconDying : this.iconDead;
    },

    iconAbilities: mdiCardAccountDetailsOutline,
    iconAccount: mdiAccount,
    iconActivate: mdiTransferUp,
    iconServerAdmin: mdiCogTransferOutline,
    iconArmorClass: mdiShieldHalfFull,
    iconAttacks: mdiSwordCross,
    iconAttitude: mdiDramaMasks,
    iconBackspace: mdiBackspaceOutline,
    iconBestiary: mdiSkull,
    iconChangePassword: mdiFormTextboxPassword,
    iconChat: mdiCommentTextMultipleOutline,
    iconChatFlag: mdiPodcast,
    iconClone: mdiContentDuplicate,
    iconClose: mdiClose,
    iconCollapse: mdiChevronUp,
    iconCondition: mdiAccountSettings,
    iconCorpses: mdiGraveStone,
    iconCreateMonster: mdiSkull,
    iconD20: mdiDiceD20,
    iconDead: mdiSkullCrossbones,
    iconDecrement: mdiWindowMinimize,
    iconDelay: mdiClockStart,
    iconDetails: mdiFingerprint,
    iconDiceBag: mdiDiceD20,
    iconDivision: mdiDivision,
    iconDying: mdiHeartOutline,
    iconEdit: mdiCodeJson,
    iconEndCombat: mdiPeace,
    iconEndTurn: mdiTransferDown,
    iconEqual: mdiEqual,
    iconEqualBox: mdiEqualBox,
    iconExpand: mdiChevronDown,
    iconExport: mdiMathLog,
    iconFamiliar: mdiPaw,
    iconFeats: mdiTrophy,
    iconFinePrint: mdiInformationVariant,
    iconFoes: mdiSkullOutline,
    iconGear: mdiTreasureChest,
    iconGrapple: mdiKabaddi,
    iconHeal: mdiHomeHeart,
    iconHealth: mdiHeart,
    iconHeroes: mdiAccountMultiple,
    iconHeroPicker: mdiAccountSearchOutline,
    iconHeroView: mdiAccountCircle,
    iconHidePassword: mdiEye,
    iconHostile: mdiSkullOutline,
    iconIncrement: mdiPlusThick,
    iconInit: mdiProgressClock,
    iconLogout: mdiLogout,
    iconLoot: mdiDiamondStone,
    iconMelee: mdiSword,
    iconMinus: mdiMinus,
    iconMoveDown: mdiArrowDown,
    iconMoveUp: mdiArrowUp,
    iconMultiply: mdiClose,
    iconNeutral: mdiEmoticonNeutralOutline,
    iconNotes: mdiNotebookOutline,
    iconNPC: mdiAccountOutline,
    iconOpenInNew: mdiOpenInNew,
    iconOwner: mdiStar,
    iconPassword: mdiLock,
    iconPlayer: mdiAccount,
    iconPlus: mdiPlus,
    iconRanged: mdiTarget,
    iconRefresh: mdiRefresh,
    iconRemove: mdiCloseBoxOutline,
    iconRemoveAll: mdiCloseBoxMultiple,
    iconRepeat: mdiUndoVariant,
    iconSavingThrows: mdiThumbsUpDown,
    iconSearch: mdiMagnify,
    iconShowPassword: mdiEyeOff,
    iconShutdown: mdiPower,
    iconSkills: mdiSchool,
    iconSpells: mdiScriptText,
    iconSpellsLink: mdiBookOpenPageVariant,
    iconSpellSlot: mdiScript,
    iconSpellSlotEmpty: mdiScriptOutline,
    iconSRDIndex: mdiBookOpenPageVariant,
    iconStats: mdiHeartPulse,
    iconSummary: mdiBadgeAccountOutline,
    iconSummonMonster: mdiBookOpenPageVariant,
    iconTalents: mdiCardAccountDetailsOutline,
    iconTheme: mdiInvertColors,
    iconToTop: mdiChevronTripleUp,
    iconTouch: mdiGestureDoubleTap,
    iconTracker: mdiSwordCross,
    iconUnique: mdiHeadOutline,
    iconUnlimited: mdiInfinity,
    iconUpload: mdiUpload,
    iconWeapons: mdiSwordCross,
    iconWebLink: mdiSearchWeb
  };
})();
