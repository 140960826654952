import Vue from "vue";
import { compareActors } from "@/models/actor";
import ActorService from "@/services/actor.service";
import { isValidObj, isValidStr } from "@/store";

function touchActor(state, actor) {
  if (!isValidObj(actor)) return;

  // console.log("actor/touchActor => %s", actor.actorName);

  actor.isFamiliar = isValidStr(actor.mapStrings["idMaster"]);
  actor.isHero = actor.unique && actor.idUser && !actor.isFamiliar;

  if (actor.getString === undefined) {
    actor.getString = function (key) {
      const prop = key && this.mapStrings ? this.mapStrings[key] : "";
      return prop || "";
    };
  }

  if (actor.getValue === undefined) {
    actor.getValue = function (key) {
      const prop = key && this.mapValues ? this.mapValues[key] : 0;
      return prop || 0;
    };
  }

  var index = state.actors.findIndex((a) => a.id == actor.id);
  if (index >= 0) Vue.set(state.actors, index, actor);

  if (actor.combatOrder > 0) {
    index = state.active.findIndex((a) => a.id == actor.id);
    if (index >= 0) Vue.set(state.active, index, actor);
  }
}

export const actor = {
  namespaced: true,
  state: {
    actors: [],
    active: [],
    current: null,
    lastUpdated: null,
  },
  actions: {
    clear: async function (ctx) {
      ctx.commit("setActor", null);
      ctx.commit("setActors", []);
    },

    clone: async function (ctx, dto) {
      // console.log("actor/clone '%o'", dto);
      return ActorService.clone(dto);
    },

    create: async function (ctx, dto) {
      // console.log("actor/create '%o'", dto);
      return ActorService.create(dto);
    },

    endTurn: async function (ctx) {
      ActorService.endTurn().then(
        (actors) => {
          ctx.commit("setActors", actors);
        },
        (err) => {
          console.log("actor/endTurn FAILED => ", err);
        }
      );
    },

    heal: async function (ctx, id) {
      ActorService.heal(id).then(
        (actor) => {
          touchActor(ctx.state, actor);
        },
        (err) => {
          console.log("actor/heal FAILED => ", err);
        }
      );
    },

    healAll: async function (ctx) {
      ActorService.healAll().then(
        (actors) => {
          ctx.commit("setActors", actors);
        },
        (err) => {
          console.log("actor/healAll FAILED => ", err);
        }
      );
    },

    inactivate: async function (ctx, id) {
      ActorService.inactivate(id).then(
        (actors) => {
          ctx.commit("setActors", actors);
        },
        (err) => {
          console.log("actor/inactivate FAILED => ", err);
        }
      );
    },

    inactivateAll: async function (ctx) {
      ActorService.inactivateAll().then(
        (actors) => {
          ctx.commit("setActors", actors);
        },
        (err) => {
          console.log("actor/inactivateAll FAILED => ", err);
        }
      );
    },

    init: async function (ctx, dto) {
      // console.log("actor/init '%o'", dto);
      return ActorService.init(dto.id, dto.initRoll);
    },

    loadAll: async function (ctx) {
      ActorService.loadAll().then(
        (actors) => {
          actors.sort(compareActors);
          ctx.commit("setActors", actors);
        },
        (err) => {
          console.log("actor/loadAll FAILED => ", err);
        }
      );
    },

    loadChat: async function (ctx, id) {
      // console.log("/actor/%s/loadChat", id);
      return ActorService.loadChat(id);
    },

    loadFamiliar: async function (ctx, id) {
      return ActorService.loadActor(id).then(
        (actor) => {
          // console.log("/actor/loadFamiliar => %o", actor);
          touchActor(ctx.state, actor);
          return Promise.resolve(actor);
        },
        (err) => {
          console.log("actor/loadFamiliar FAILED => ", err);
          return Promise.reject(err);
        }
      );
    },

    loadGear: async function (ctx, id) {
      // console.log("/actor/%s/loadGear", id);
      return ActorService.loadGear(id);
    },

    loadLoot: async function (ctx, id) {
      // console.log("/actor/%s/loadLoot", id);
      return ActorService.loadLoot(id);
    },

    loadNotes: async function (ctx, id) {
      // console.log("/actor/%s/loadNotes", id);
      return ActorService.loadNotes(id);
    },

    loadRaw: async function (ctx, id) {
      return ActorService.loadRaw(id);
    },

    makeActive: async function (ctx, id) {
      ActorService.makeActive(id).then(
        (actors) => {
          ctx.commit("setActors", actors);
        },
        (err) => {
          console.log("actor/makeActive FAILED => ", err);
        }
      );
    },

    remove: async function (ctx, id) {
      ActorService.remove(id).then(
        (actors) => {
          ctx.commit("setActors", actors);
        },
        (err) => {
          console.log("actor/remove FAILED => ", err);
        }
      );
    },

    removeAll: async function (ctx) {
      ActorService.removeAll().then(
        (actors) => {
          ctx.commit("setActors", actors);
        },
        (err) => {
          console.log("actor/removeAll FAILED => ", err);
        }
      );
    },

    reorder: async function (ctx, dto) {
      // console.log("actor/reorder '%o'", dto);
      ActorService.reorder(dto.id, dto.delta).then(
        (actors) => {
          ctx.commit("setActors", actors);
        },
        (err) => {
          console.log("actor/reorder FAILED => ", err);
        }
      );
    },

    search: async function (ctx, searchKey) {
      // console.log("actor/search '%s'", searchKey);
      return ActorService.search(searchKey).then(
        (response) => {
          // console.log("actor/search => %o", response);
          return Promise.resolve(response);
        },
        (err) => {
          console.log("actor/search FAILED => ", err);
          return Promise.reject(err);
        }
      );
    },

    select: async function (ctx, id) {
      const actor = ctx.state.actors.find((a) => a.id === id);
      // console.log("actor/select/%s => %o", id, actor);
      if (actor) ctx.commit("setActor", id);
    },

    selectByOwner: async function (ctx, idUser) {
      const actor = ctx.state.actors.find((a) => a.idUser === idUser && !isValidStr(a.mapStrings["idMaster"]));
      // console.log("actor/selectByOwner/%s => %o", idUser, actor);
      if (actor) ctx.commit("setActor", actor.id);
    },

    update: async function (ctx, dto) {
      ActorService.update(dto).then(
        (actor) => {
          touchActor(ctx.state, actor);
        },
        (err) => {
          console.log("actor/update FAILED => ", err);
        }
      );
    },

    updateChat: async function (ctx, dto) {
      // console.log("actor/updateChat => %o", dto);
      return ActorService.updateChat(dto);
    },

    updateGear: async function (ctx, dto) {
      // console.log("actor/updateGear => %o", dto);
      return ActorService.updateGear(dto);
    },

    updateLoot: async function (ctx, dto) {
      // console.log("actor/updateLoot => %o", dto);
      return ActorService.updateLoot(dto);
    },

    updateNotes: async function (ctx, dto) {
      // console.log("actor/updateNotes => %o", dto);
      return ActorService.updateNotes(dto);
    },

    updateRaw: async function (ctx, actor) {
      // console.log("actor/updateRaw => %o", actor);
      ActorService.updateRaw(actor).then(
        (actor) => {
          touchActor(ctx.state, actor);
        },
        (err) => {
          console.log("actor/updateRaw FAILED => ", err);
        }
      );
    },
  },
  getters: {
    all: (state) => {
      return state.actors || [];
    },
    allActive: (state) => {
      return state.active || [];
    },
    allInactive: (state) => {
      const actors = state.actors || [];
      return actors.filter((actor) => actor.combatOrder == 0);
    },
    allUnique: (state) => {
      const actors = state.actors || [];
      return actors.filter((a) => a.unique).sort(compareActors);
    },
    current: (state) => {
      return state.actors.find((a) => a.id === state.current);
    },
  },
  mutations: {
    setActor(state, id) {
      state.current = id;
    },
    setActors(state, actors) {
      state.active = [];
      state.actors = actors || [];

      state.actors.forEach((actor) => {
        touchActor(state, actor);
        if (actor.combatOrder > 0) state.active.push(actor);
      });

      state.active.sort(function (lhs, rhs) {
        return lhs.combatOrder - rhs.combatOrder;
      });

      state.lastUpdated = new Date();
    },
  },
};
