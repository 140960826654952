<template>
  <div id="nav-bar">
    <v-app-bar app clipped-left color="amber" light>
      <v-app-bar-nav-icon @click="onToggleSidebar" />
      <span class="ml-2 title truncate">{{ caption }}</span>
      <v-spacer />
      <v-menu bottom close-on-click offset-y v-if="isDM">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>{{ icons.iconHeroPicker }}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(actor, index) in roster"
            :key="index"
            @click="onPickActor(actor)"
          >
            <v-list-item-title>{{ actor.actorName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn class="mx-1" icon @click.stop="onToggleDiceBag">
        <v-icon>{{ icons.iconDiceBag }}</v-icon>
      </v-btn>
      <v-btn class="mx-1" icon @click.stop="onRefresh">
        <v-icon>{{ icons.iconRefresh }}</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app clipped v-model="drawer">
      <v-list dense>
        <v-list-item @click.stop="onToggleTheme">
          <v-list-item-icon>
            <v-icon>{{ icons.iconTheme }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ themeLabel }}</v-list-item-title>
        </v-list-item>
        <v-list-item target="_blank" :href="linkSRD">
          <v-list-item-icon>
            <v-icon>{{ icons.iconSRDIndex }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>SRD Index</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click.stop="onUserUpdate">
          <v-list-item-icon>
            <v-icon>{{ icons.iconChangePassword }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Change&nbsp;Password</v-list-item-title>
        </v-list-item>
        <v-list-item @click.stop="onServerAdmin" v-if="isServerAdmin">
          <v-list-item-icon>
            <v-icon>{{ icons.iconServerAdmin }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Server&nbsp;Administration</v-list-item-title>
        </v-list-item>
        <v-list-item @click.stop="onLogOut">
          <v-list-item-icon>
            <v-icon>{{ icons.iconLogout }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Log&nbsp;Out</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click.stop="onFinePrint">
          <v-list-item-icon>
            <v-icon>{{ icons.iconFinePrint }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>About&hellip;</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <FinePrint
      :visible="showFinePrint"
      v-on:close-fine-print="showFinePrint = false"
    />
    <DiceBag :visible="showDiceBag" />
    <ServerAdmin
      :visible="showServerAdmin"
      v-on:close-server-admin="showServerAdmin = false"
    />
    <UserUpdate
      :visible="showUserUpdate"
      v-on:close-user-update="showUserUpdate = false"
    />
  </div>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import DiceBag from "@/dialogs/DiceBag";
import FinePrint from "@/dialogs/FinePrint";
import ServerAdmin from "@/dialogs/ServerAdmin";
import UserUpdate from "@/dialogs/UserUpdate";
import apiCall from "@/services";
import { linkToSRD } from "@/services";
import { isValidObj, isValidStr } from "@/store";

export default {
  name: "NavBar",

  components: {
    DiceBag,
    FinePrint,
    ServerAdmin,
    UserUpdate,
  },
  computed: {
    caption() {
      const actor = this.$store.getters["actor/current"];

      if (actor && isValidStr(actor.actorName)) {
        const jewel = actor.mapValues["jewel"];

        return jewel > 0 ? `#${jewel}: ${actor.actorName}` : actor.actorName;
      }

      return "Hero Codex";
    },
    currentUser() {
      return this.$store.getters["user/current"];
    },
    isDM() {
      return this.$store.getters["user/isDM"];
    },
    isServerAdmin() {
      return this.$store.getters["user/isServerAdmin"];
    },
    roster() {
      return this.$store.getters["actor/allUnique"];
    },
    showDiceBag() {
      return this.$store.getters["showDiceBag"];
    },
    themeLabel() {
      return this.$vuetify.theme.isDark ? "Light Theme" : "Dark Theme";
    },
  },
  data: () => ({
    drawer: null,
    icons: IconLib,
    linkSRD: linkToSRD("/srd/index.html"),
    showFinePrint: false,
    showServerAdmin: false,
    showUserUpdate: false,
  }),
  methods: {
    onFinePrint() {
      this.showFinePrint = !this.showFinePrint;
    },
    onLogOut() {
      this.$store.dispatch("actor/clear");
      this.$store.dispatch("user/logout");
      this.drawer = false;
    },
    onPickActor(actor) {
      if (actor) {
        // console.log("onPickActor( %s )", actor.actorName);
        this.$store.dispatch("actor/select", actor.id);
        this.$store.commit("setViewTab", "tab-hero");
      }
    },
    onRefresh() {
      if (this.currentUser != null) {
        this.$store.dispatch("updateAll").then(() => {
          if (!isValidObj(this.$store.getters["actor/current"]))
            this.$store.dispatch("actor/selectByOwner", this.currentUser.id);
        });
      }

      this.drawer = false;
    },
    onServerAdmin() {
      this.showServerAdmin = this.isServerAdmin;
    },
    onToggleDiceBag() {
      this.drawer = false;
      this.$store.dispatch("toggleDiceBag");
    },
    onToggleSidebar() {
      return (this.drawer = !this.drawer && this.enabled);
    },
    onToggleTheme() {
      this.$vuetify.theme.isDark = !this.$vuetify.theme.isDark;
      this.drawer = false;
    },
    onShutdown() {
      apiCall("put", "/admin/shutdown");
    },
    onUserUpdate() {
      this.showUserUpdate = this.currentUser != null;
    },
  },
  props: {
    enabled: { type: Boolean, required: false, default: false }
  },
};
</script>

<style scoped>
.truncate {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
