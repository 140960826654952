<template>
  <v-container id="layout-single" class="pa-2" fluid>
    <v-tabs color="brown darken-1" grow v-model="tab">
      <v-tab>
        <v-icon>{{ icons.iconHeroView }}</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>{{ icons.iconTracker }} </v-icon>
      </v-tab>
      <v-tab-item><HeroView :hero="theActor" :isDM="isDM"/></v-tab-item>
      <v-tab-item><Tracker /></v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import HeroView from "@/views/HeroView";
import Tracker from "@/views/Tracker";

export default {
  name: "LayoutSingle",

  components: {
    HeroView,
    Tracker,
  },
  computed: {
    isDM() {
      return this.$store.getters["user/isDM"];
    },
    tab: {
      get() {
        return this.$store.getters["viewTab"];
      },
      set(value) {
        this.$store.commit("setViewTab", value);
      },
    },
    theActor() {
      return this.$store.getters["actor/current"];
    },
    theUser() {
      return this.$store.getters["user/current"];
    },
  },
  data() {
    return {
      icons: IconLib,
    };
  },
  mounted() {
    this.$store.dispatch("updateAll");
  },
};
</script>
