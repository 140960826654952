<template>
  <v-form id="actor-gear" v-if="actor.unique" @submit.prevent="onSubmit">
    <v-textarea
      counter="0"
      full-width
      outlined
      placeholder="Don't touch my stuff..."
      v-model.trim="text"
      :rules="rules"
    />
    <v-toolbar dense flat>
      <v-btn small text @click.stop="load">
        Undo
      </v-btn>
      <v-spacer />
      <v-btn color="primary" small type="submit">
        Save
      </v-btn>
    </v-toolbar>
  </v-form>
  <NothingBlock v-else />
</template>

<script>
import NothingBlock from "@/blocks/NothingBlock";
import { MAX_TEXT_LEN } from "@/models/actor";

export default {
  name: "ActorGear",

  components: {
    NothingBlock
  },
  created() {
    this.load();
  },
  data: () => ({
    idGear: null,
    rules: [
      v => v.length <= MAX_TEXT_LEN || `Max ${MAX_TEXT_LEN} characters`
    ],
    text: ""
  }),
  methods: {
    load() {
      this.$store
        .dispatch("actor/loadGear", this.actor.id)
        .then(dto => {
          // console.log("ActorGear::load() => %o", dto);
          this.idGear = dto.id;
          this.text = dto.text || "";
        })
        .catch(() => (this.text = ""));
    },
    onSubmit() {
      const data = {
        id : this.idGear,
        idActor : this.actor.id,
        text : this.text || ""
      }
      // console.log("ActorGear::onSubmit() => %o", data);
      this.$store.dispatch("actor/updateGear", data);
    }
  },
  props: {
    actor: { type: Object, required: true }
  },
  watch: {
    actor: function() {
      this.load();
    }
  }
};
</script>
