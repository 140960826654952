<template>
  <v-row id="experience-block" align="center" no-gutters>
    <v-col cols="4">
      <ValBlock label="CR" :value="actor.getString('challengeRating')" />
    </v-col>
    <v-col cols="4">
      <ValBlock label="XP" :showCommas="true" :value="actor.getValue('xpVal')" />
    </v-col>
    <v-col cols="4">
      <ValBlock label="Hit Dice" :value="actor.getString('hitDice')" />
    </v-col>
  </v-row>
</template>

<script>
import ValBlock from "@/blocks/ValBlock";

export default {
  name: "ChallengeBlock",

  components: {
    ValBlock
  },
  props: {
    actor: { type: Object, required: true }
  }
};
</script>
