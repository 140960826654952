<template>
  <v-dialog id="actor-edit" max-width="800" persistent v-model="showDialog">
    <v-card flat>
      <v-card-title>
        <v-row dense>
          <v-col cols="12">
            <TitleBlock :actor="actor" />
          </v-col>
          <v-col cols="12">
            {{ actor.actorName }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <JsonEditor
          :options="options"
          :value="jsonData"
          v-on:input="onChange"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click.stop="closeDialog">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn class="mx-2" icon outlined @click.stop="onReload">
          <v-icon>{{ icons.iconRefresh }}</v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          icon
          outlined
          @click.stop="onActorClone"
          :disabled="actor.unique"
        >
          <v-icon>{{ icons.iconClone }}</v-icon>
        </v-btn>
        <v-btn class="mx-2" icon outlined @click.stop="onActorExport">
          <v-icon>{{ icons.iconExport }}</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          class="mx-2"
          color="primary"
          type="submit"
          :disabled="!editedData"
          @click.stop="onSubmit"
        >
          <v-icon class="mr-2">{{ icons.iconUpload }}</v-icon>
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import TitleBlock from "@/blocks/TitleBlock";
import { ActorInitDTO } from "@/models/actor";
import JsonEditor from "@/plugins/JsonEditor";
import { isValidStr } from "@/store";

export default {
  name: "ActorEdit",
  components: {
    JsonEditor,
    TitleBlock,
  },
  computed: {
    showDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      editedData: null,
      icons: IconLib,
      jsonData: {},
      options: {
        escapeUnicode: false,
        history: true,
        mode: "tree",
        modes: ["text", "tree"],
        mainMenuBar: true,
        navigationBar: false,
        search: true,
        statusBar: false,
      },
    };
  },
  methods: {
    closeDialog: function() {
      this.$emit("close-actor-edit");
    },
    onActorClone() {
      const dto = new ActorInitDTO(this.actor.id, 1, false);

      this.$store.dispatch("actor/clone", dto);
    },
    onActorExport() {
      this.$store.dispatch("actor/loadRaw", this.actor.id).then((actor) => {
        const json = JSON.stringify(actor);

        if (isValidStr(json)) {
          console.log("--- Export Start ---");
          console.log(json);
          console.log("--- Export End ---");

          this.$copyText(json).then(
            function() {},
            function(err) {
              alert("Export failed.");
              console.log(err);
            }
          );
        }
      });
    },
    onChange(data) {
      // console.log("ActorEdit::onChange() => %o", data);
      this.editedData = data;
    },
    onReload() {
      let id = this.editTemplate ? this.actor.getString("idTemplate") : null;

      if (!isValidStr(id)) id = this.actor.id;

      this.$store.dispatch("actor/loadRaw", id).then((actor) => {
        // console.log("ActorEdit::onReload() => %s", actor.actorName);
        this.jsonData = actor;
        this.editedData = null;
      });
    },
    onSubmit() {
      if (this.editedData) {
        this.$store.dispatch("actor/updateRaw", this.editedData);
      } else {
        console.log("ActorEdit::onSubmit() => no changes");
      }
      this.closeDialog();
    },
  },
  props: {
    actor: { type: Object, required: false, default: null },
    editTemplate: { type: Boolean, required: false, default: false },
    visible: { type: Boolean, required: true, default: false },
  },
  watch: {
    showDialog: function() {
      if (this.visible) this.onReload();
    },
  },
};
</script>
