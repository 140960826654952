<template>
  <v-row id="tracker" dense>
    <v-toolbar class="elevation-0" dense v-if="isDM">
      <v-btn color="error" outlined small @click.stop="onEndCombat" :disabled="!hasActors">
        <v-icon class="mr-2">{{ icons.iconEndCombat }}</v-icon>
        End&nbsp;Combat
      </v-btn>
    </v-toolbar>
    <v-col cols="12" v-for="actor in actors" :key="actor.id">
      <TrackerCard :actor="actor" :isDM="isDM" />
    </v-col>
    <Confirm ref="confirm" />
  </v-row>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import TrackerCard from "@/components/TrackerCard";
import Confirm from "@/plugins/Confirm";
import { isNotEmpty } from "@/store";

export default {
  name: "Tracker",

  components: {
    Confirm,
    TrackerCard
  },
  computed: {
    actors() {
      return this.$store.getters["actor/allActive"];
    },
    hasActors() {
      return isNotEmpty(this.actors);
    },
    isDM() {
      return this.$store.getters["user/isDM"];
    }
  },
  data() {
    return {
      icons: IconLib
    };
  },
  methods: {
    onEndCombat() {
      this.$refs.confirm
        .open("End Combat", "Clear all combatants?", { color: "warning" })
        .then(confirmed => {
          if (confirmed) this.$store.dispatch("actor/inactivateAll");
        });
    }
  }
};
</script>
