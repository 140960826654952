<template>
  <v-dialog id="fine-print" max-width="640" persistent v-model="showDialog">
    <v-card>
      <v-card-title>
        About&nbsp;HeroCodex&hellip;
        <v-spacer />
        <v-btn icon small @click.stop="closeDialog">
          <v-icon>{{ icons.iconClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        <v-row class="pt-1">
          <v-col cols="auto">
            <v-img
              :src="require('@/assets/logo-128x128.png')"
              contain
              height="64"
              width="64"
            ></v-img>
          </v-col>
          <v-col>
            <v-row>
              <span class="subtitle-1">
                Designed &amp; coded by Humble Programmer
              </span>
            </v-row>
            <v-row>
              <span class="subtitle-2">
                Beta-tested by Crew&nbsp;1430 and the Four Caballeros
              </span>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="mt-2" />
      </v-card-subtitle>
      <v-card-text class="my-0 py-0">
        <span class="caption">HeroCodex was built with:</span>
        <v-list dense>
          <v-list-item dense href="https://vuejs.org" target="_blank">
            <v-list-item-avatar>
              <v-img
                :src="require('@/assets/vuejs-32x32.png')"
                class="my-1"
                contain
                height="32"
                width="32"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-title>
              Vue.js
            </v-list-item-title>
          </v-list-item>
          <v-list-item dense href="https://vuetifyjs.com" icon target="_blank">
            <v-list-item-avatar>
              <v-img
                :src="require('@/assets/vuetify-32x32.png')"
                class="my-1"
                contain
                height="32"
                width="32"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-title>
              Vuetify
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            dense
            href="https://materialdesignicons.com"
            target="_blank"
          >
            <v-list-item-avatar>
              <v-img
                :src="require('@/assets/materialdesign-32x32.png')"
                class="my-1"
                contain
                height="32"
                width="32"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-title>
              Material&nbsp;Design&nbsp;Icons
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            dense
            href="https://github.com/josdejong/jsoneditor"
            target="_blank"
          >
            <v-list-item-avatar>
              <v-icon class="mdi-24px">{{ icons.iconEdit }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              JSON&nbsp;Editor
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider />
      </v-card-text>
      <v-card-actions class="justify-center">
        <span class="title">
          Version&nbsp;{{ this.$store.state.version }}
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { IconLib } from "@/assets/iconLib";

export default {
  name: "FinePrint",

  computed: {
    showDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      icons: IconLib,
    };
  },
  methods: {
    closeDialog: function() {
      this.$emit("close-fine-print");
    },
    onReset() {
      // console.log("FinePrint::onReset()");
    },
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  watch: {
    showDialog: function() {
      if (this.visible) this.onReset();
    },
  },
};
</script>
