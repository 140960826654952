<template>
  <v-row id="actor-details" class="pa-1" dense v-if="actor">
    <v-col cols="12" v-if="hasClasses">
      <v-list class="px-0 pb-1 pt-2" dense flat>
        <ClassBlock
          v-for="(classInfo, index) in actor.classes"
          :classInfo="classInfo"
          :key="index"
        />
      </v-list>
      <v-divider />
    </v-col>
    <v-col cols="12">
      <ExperienceBlock :actor="actor" v-if="xpGoal" />
      <ChallengeBlock :actor="actor" v-else />
    </v-col>
    <v-col cols="4">
      <ValBlock label="Race / Type" :value="race" v-if="race" />
    </v-col>
    <v-col cols="4">
      <ValBlock label="Size" :value="size" v-show="size" />
    </v-col>
    <v-col cols="4">
      <ValBlock label="Alignment" :value="alignment" v-show="alignment" />
    </v-col>
    <v-col cols="12" v-show="senses">
      <ValBlock label="Senses" :muted="true" :value="senses" />
    </v-col>
    <v-col cols="12" v-show="languages">
      <ValBlock label="Languages" :muted="true" :value="languages" />
    </v-col>
    <v-col cols="12" v-show="immunities">
      <ValBlock label="Immunities" :muted="true" :value="immunities" />
    </v-col>
    <v-col cols="12" v-show="resistances">
      <ValBlock label="Resistances" :muted="true" :value="resistances" />
    </v-col>
  </v-row>
  <NothingBlock v-else />
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import ChallengeBlock from "@/blocks/ChallengeBlock";
import ClassBlock from "@/blocks/ClassBlock";
import ExperienceBlock from "@/blocks/ExperienceBlock";
import NothingBlock from "@/blocks/NothingBlock";
import ValBlock from "@/blocks/ValBlock";

import { isNotEmpty } from "@/store";

export default {
  name: "ActorDetails",

  components: {
    ChallengeBlock,
    ClassBlock,
    ExperienceBlock,
    NothingBlock,
    ValBlock,
  },
  computed: {
    hasClasses() {
      return isNotEmpty(this.actor.classes);
    },
    alignment() {
      return this.actor ? this.actor.getString("alignment") : "";
    },
    immunities() {
      return this.actor ? this.actor.getString("immunities") : "";
    },
    languages() {
      return this.actor ? this.actor.getString("languages") : "";
    },
    race() {
      return this.actor ? this.actor.getString("race") : "";
    },
    resistances() {
      return this.actor ? this.actor.getString("resistances") : "";
    },
    senses() {
      return this.actor ? this.actor.getString("senses") : "";
    },
    size() {
      return this.actor ? this.actor.getString("size") : "";
    },
    xpGoal() {
      return this.actor ? this.actor.getValue("xpGoal") : 0;
    },
  },
  data: () => ({
    icons: IconLib,
  }),
  props: {
    actor: { type: Object, required: true },
    isDM: { type: Boolean, required: false, default: false },
  },
};
</script>
