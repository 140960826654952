import { isValidArray, isValidStr } from "@/store";

export const MAX_TEXT_LEN = 2500;

export class ActorCreateDTO {
  constructor(
    name,
    initiative,
    melee,
    armorClass,
    ranged,
    hitDice,
    actorCount,
    attitude
  ) {
    this.name = name;
    this.initiative = initiative || "0";
    this.melee = melee || "";
    this.armorClass = armorClass || "";
    this.ranged = ranged || "";
    this.hitDice = hitDice || "";
    this.actorCount = Number.isInteger(actorCount) ? actorCount : 1;
    this.attitude = attitude || HOSTILE;
  }
}

export class ActorInitDTO {
  constructor(id, actorCount, autoInit, attitude) {
    this.id = id;
    this.actorCount = actorCount;
    this.autoInit = !!autoInit;
    this.attitude = attitude || NEUTRAL;
  }
}

export class ActorUpdateDTO {
  constructor(
    id,
    hitPoints,
    readyAction, // 1
    attitude, // 2
    conditions, // 4
    spells, // 8
    talents // 16
  ) {
    this.id = id;
    this.flags = 0;
    this.hitPoints = hitPoints;

    if (Number.isInteger(readyAction) && readyAction >= 0) {
      this.readyAction = readyAction;
      this.flags += 1;
    } else this.readyAction = -1;

    if (isValidStr(attitude)) {
      this.attitude = attitude;
      this.flags += 2;
    } else this.attitude = "";

    if (isValidArray(conditions)) {
      this.conditions = conditions;
      this.flags += 4;
    } else this.conditions = [];

    if (isValidArray(spells)) {
      this.spells = spells;
      this.flags += 8;
    } else this.spells = [];

    if (isValidArray(talents)) {
      this.talents = talents;
      this.flags += 16;
    } else this.talents = [];
  }
}

export class TextDTO {
  constructor(id, text) {
    this.id = id || "";
    this.text = text || "";
  }
}

export function compareActors(lhs, rhs) {
  var cmp;

  if (lhs && rhs) {
    //  Bucket #1 -- put all the player-owned actors first.
    var lhsBucket = lhs.idUser ? 0 : 1;
    var rhsBucket = rhs.idUser ? 0 : 1;

    cmp = lhsBucket - rhsBucket;
    if (cmp == 0) {
      // Bucket #2 -- sort heroes above familiars
      lhsBucket = isValidStr(lhs.mapStrings["idMaster"]) ? 1 : 0;
      rhsBucket = isValidStr(rhs.mapStrings["idMaster"]) ? 1 : 0;
      cmp = lhsBucket - rhsBucket;

      if (cmp == 0) {
        // Bucket #3 -- sort by jewel number, but only if both have one
        lhsBucket = lhs.mapValues["jewel"] || 0;
        rhsBucket = rhs.mapValues["jewel"] || 0;
        cmp = lhsBucket > 0 && rhsBucket > 0 ? lhsBucket - rhsBucket : 0;

        if (cmp == 0) {
          // Bucket #4 -- sort alphabetically by name.
          cmp = lhs.actorName.localeCompare(rhs.actorName);
        }
      }
    }
  } else {
    cmp = rhs ? -1 : +1;
  }

  return cmp;
}

export function getConditionColor(str) {
  if (isValidStr(str)) {
    switch (str[0]) {
      case "+":
        return "green darken-3";
      case "-":
        return "red darken-3";
    }
  }

  return "black";
}

export function getConditionText(str) {
  return isValidStr(str) && (str[0] == "-" || str[0] == "+")
    ? str.substring(1)
    : str || "";
}

export const FRIENDLY = "Friendly";
export const NEUTRAL = "Neutral";
export const HOSTILE = "Hostile";

export const Attitudes = [FRIENDLY, NEUTRAL, HOSTILE];

export function isHostileActor(actor) {
  return actor && actor.mapStrings["attitude"] == HOSTILE;
}
