import UserService from "@/services/user.service";

const localUser = window.$cookies ? window.$cookies.get("herobook-user") : null;

// console.log("localUser: %o", localUser );

export const user = {
  namespaced: true,
  state: {
    currentUser: localUser
  },
  actions: {
    //
    //  Attempts to log a user in.
    //
    login(ctx, user) {
      return UserService.login(user).then(
        user => {
          ctx.commit("set", user);
          return Promise.resolve(user);
        },
        error => {
          ctx.commit("clear");
          return Promise.reject(error);
        }
      );
    },
    //
    //  Logs the user out, clearing the current user.
    //
    logout(ctx) {
      ctx.commit("clear");
    },

    update: async function (ctx, dto) {
      // console.log("user/update '%o'", dto);
      return UserService.update(dto);
    },

  },
  getters: {
    //  Returns the current user, if any.
    current(state) {
      return state.currentUser;
    },
    //  Returns .T. if the current user is a DM
    isDM(state) {
      return state.currentUser && state.currentUser.role === "Admin";
    },
    //  Returns .T. if the current user is a Server Admin
    isServerAdmin(state) {
      return state.currentUser && state.currentUser.serverAdmin;
    }
  },
  mutations: {
    clear(state) {
      // console.log("user/clear");
      state.currentUser = null;
      window.$cookies.remove("herobook-user");
    },
    set(state, user) {
      console.log("user/set(%s) => %o", user.id, user);
      state.currentUser = user;
      window.$cookies.set("herobook-user", user, "1d");
    }
  }
};
