<template>
  <v-container>
    <v-list id="actor-attacks" dense v-if="hasActions">
      <v-list-item
        dense
        v-for="(action, index) in actor.actions"
        :class="textColor(index)"
        :key="index"
      >
        <ActionBlock
          class="actionBlock"
          :action="action"
          :expandable="true"
          :readied="index === readyAction"
        />
      </v-list-item>
    </v-list>
    <ValBlock label="Base Attack Bonus" :value="bab" />
  </v-container>
</template>

<script>
import { isNotEmpty } from "@/store";
import ActionBlock from "@/blocks/ActionBlock";
import ValBlock from "@/blocks/ValBlock";

export default {
  name: "ActorAttacks",

  components: {
    ActionBlock,
    ValBlock,
  },
  computed: {
    bab() {
      return this.actor.getString("bab");
    },
    hasActions() {
      return isNotEmpty(this.actor.actions);
    },
    readyAction() {
      return this.actor.getValue("readyAction") || 0;
    },
  },
  methods: {
    textColor(index) {
      if (index === this.readyAction) {
        return this.$vuetify.theme.isDark
          ? "blue-grey darken-3"
          : "blue-grey lighten-5";
      }

      return "default";
    },
  },
  props: {
    actor: { type: Object, required: true },
  },
};
</script>
