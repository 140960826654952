<template>
  <v-app>
    <NavBar :enabled="isLoggedIn" />
    <v-main v-if="isLoggedIn">
      <!-- Hide on screens smaller than md -->
      <LayoutDouble v-if="this.$vuetify.breakpoint.mdAndUp" />
      <!-- Show only on xs,sm devices -->
      <LayoutSingle v-else />
    </v-main>
    <v-main v-else>
      <Login />
    </v-main>
  </v-app>
</template>

<script>
import LayoutDouble from "@/components/LayoutDouble";
import LayoutSingle from "@/components/LayoutSingle";
import NavBar from "@/components/NavBar";

import Login from "@/views/Login";

export default {
  name: "App",

  components: {
    LayoutDouble,
    LayoutSingle,
    Login,
    NavBar
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/current"] !== null;
    }
  }
};
</script>

<style>
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.is-dead {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23FFE7E7" d="M15.8,18.5L21.8,20.1L21.4,22L12,19.5L2.6,22L2.1,20.1L8.1,18.5L2,16.9L2.5,15L11.9,17.5L21.3,15L21.8,16.9L15.8,18.5M9.5,6C8.7,6 8,6.7 8,7.5C8,8.3 8.7,9 9.5,9C10.3,9 11,8.3 11,7.5C11,6.7 10.3,6 9.5,6M14.5,6C13.7,6 13,6.7 13,7.5C13,8.3 13.7,9 14.5,9C15.3,9 16,8.3 16,7.5C16,6.7 15.3,6 14.5,6M13,11L12,9L11,11H13M12,1C8.1,1 5,4.1 5,8C5,9.9 5.8,11.6 7,12.9V16H17V12.9C18.2,11.6 19,9.9 19,8C19,4.1 15.9,1 12,1M15,12V14H14V12H13V14H11V12H10V14H9V12H9C7.8,11.1 7,9.7 7,8C7,5.2 9.2,3 12,3C14.8,3 17,5.2 17,8C17,9.6 16.2,11.1 15,12Z"></path></svg>');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.is-first {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23DCDCDC" d="M6.2,2.44L18.1,14.34L20.22,12.22L21.63,13.63L19.16,16.1L22.34,19.28C22.73,19.67 22.73,20.3 22.34,20.69L21.63,21.4C21.24,21.79 20.61,21.79 20.22,21.4L17,18.23L14.56,20.7L13.15,19.29L15.27,17.17L3.37,5.27V2.44H6.2M15.89,10L20.63,5.26V2.44H17.8L13.06,7.18L15.89,10M10.94,15L8.11,12.13L5.9,14.34L3.78,12.22L2.37,13.63L4.84,16.1L1.66,19.29C1.27,19.68 1.27,20.31 1.66,20.7L2.37,21.41C2.76,21.8 3.39,21.8 3.78,21.41L7,18.23L9.44,20.7L10.85,19.29L8.73,17.17L10.94,15Z"></path></svg>');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

</style>
