<template>
  <v-dialog id="server-admin" max-width="768" persistent v-model="showDialog">
    <v-form @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">{{ icons.iconServerAdmin }}</v-icon>
          Server&nbsp;Administration
          <v-spacer />
          <v-btn color="grey" small text @click="closeDialog">
            <v-icon>{{ icons.iconClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            autocomplete="off"
            clearable
            label="Command"
            name="command"
            required
            single-line
            v-model.trim="form.command"
          />
          <v-textarea
            class="mt-2 server-output"
            background-color="grey darken-4"
            dark
            dense
            filled
            outlined
            readonly
            rows="8"
            v-model.trim="form.serverOutput"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="closeDialog">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn color="error" @click.stop="onShutdown">
            <v-icon class="mr-2">{{ icons.iconShutdown }}</v-icon>
            Shutdown
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import apiCall from "@/services";
import { IconLib } from "@/assets/iconLib";
import { isValidStr } from "@/store";

class ServerAdminDTO {
  constructor(command) {
    this.command = command || "";
  }
}

export default {
  name: "ServerAdmin",

  computed: {
    showDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      icons: IconLib,
      // form fields
      form: {
        command: "",
        serverOutput: "",
      },
    };
  },
  methods: {
    closeDialog: function() {
      this.$emit("close-server-admin");
    },
    onReset() {
      this.form.command = "";
      this.form.serverOutput = "Enter 'help' for a list of commands.";
    },
    onShutdown() {
      // console.log("ServerAdmin::onShutdown()");
      apiCall("put", "/admin/shutdown");

      this.$store.dispatch("actor/clear");
      this.$store.dispatch("user/logout");
      this.$emit("close-server-admin");
    },
    onSubmit() {
      const dto = new ServerAdminDTO(this.form.command);

      if (isValidStr(dto.command)) {
        console.log("ServerAdmin::onSubmit() => '%o'", dto);

        apiCall("put", "/admin/command", dto).then(
          (message) => {
            // console.log("ServerAdmin::onSubmit() returned => ", message);
            this.form.serverOutput = message || "";
            this.form.command = "";
          },
          (err) => {
            console.log("ServerAdmin::onSubmit() FAILED => ", err);
          }
        );
      }
    },
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  watch: {
    showDialog: function() {
      if (this.visible) this.onReset();
    },
  },
};
</script>

<style scoped>
.server-output {
  font-family: "Consolas", Courier, monospace;
}
</style>
