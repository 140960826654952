import axios from "axios";
import { isValidStr } from "@/store";

export const codexUrl = ""; // "http://127.0.0.1:3802";

const authHeader = () => {
  const user = window.$cookies.get("herobook-user");

  return user && user.accessToken
    ? { Authorization: user.accessToken }
    : { Authorization: "anonymous" };
};

const apiCall = async function callBackend(httpMethod, urlSuffix, payload) {
  const response = await axios({
    method: httpMethod,
    url: codexUrl + urlSuffix,
    headers: authHeader(),
    data: payload
  });

  if (response.status === 200) return Promise.resolve(response.data);

  //  Scientific progress goes "bonk".
  const error = response.data && response.data.message;

  console.log(`apiCall( '${urlSuffix}' ) failed => `, error);

  return Promise.reject(error);
};

export function linkToSRD(urlSuffix) {
  if (isValidStr(codexUrl)) {
    return codexUrl + urlSuffix;
  } else if (urlSuffix.startsWith("/srd/")) {
    return "http://legacy.aonprd.com" + urlSuffix.substring(4);
  } else {
    return urlSuffix;
  }
}

export default apiCall;
