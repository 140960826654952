<template>
  <v-dialog id="party-init" max-width="640" persistent v-model="showDialog">
    <v-form @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>Party Initiative</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(info, index) in form.info" :key="index">
              <v-row align="center" dense>
                <v-col cols="6">
                  <v-row dense>
                    <span class="mx-2 subtitle-1">{{
                      info.actor.actorName
                    }}</span>
                  </v-row>
                  <v-row dense>
                    <span class="mx-2 overline">
                      {{ info.actor.getString("playerName") }}
                    </span>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    autocomplete="off"
                    label="Initiative Roll"
                    name="initRoll"
                    ref="initRollField"
                    type="number"
                    v-model.trim="info.initRoll"
                  ></v-text-field>
                </v-col>
                <v-col class="text-center" cols="2">
                  <ValBlock
                    label="Init"
                    :value="info.actor.getValue('initiative')"
                    :showSign="true"
                  />
                </v-col>
                <v-col class="text-center" cols="2">
                  <v-btn fab small @click.stop="info.rollDice">
                    <v-icon>{{ icons.iconD20 }} </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="closeDialog">
            Cancel
          </v-btn>
          <v-btn text @click.stop="onReset">
            Reset
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import ValBlock from "@/blocks/ValBlock";
import { isValidStr, rollD20 } from "@/store";

class InitInfo {
  constructor(actor) {
    this.actor = actor;
    this.initRoll = 0;

    if (!isValidStr(actor.idUser)) this.rollDice();
  }

  rollDice() {
    this.initRoll = rollD20(this.actor.getValue("initiative"));
  }
}

export default {
  name: "PartyInit",
  components: {
    ValBlock
  },
  computed: {
    showDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  data() {
    return {
      icons: IconLib,
      //  form fields
      form: {
        info: []
      }
    };
  },
  methods: {
    closeDialog: function() {
      this.$emit("close-party-init");
    },
    onReset() {
      // console.log("PartyInit::onReset()");
      this.form.info = [];
      if (this.actors && this.actors.length) {
        this.actors.forEach(actor => {
          this.form.info.push(new InitInfo(actor));
        });
      }
    },
    onSubmit() {
      this.form.info
        .filter(i => i.initRoll)
        .forEach(info => {
          const dto = {
            id: info.actor.id,
            initRoll: info.initRoll
          };

          // console.log("PartyInfo::onSubmit() => %o", dto);
          this.$store.dispatch("actor/init", dto);
        });

      this.$store.dispatch("updateAll");
      this.closeDialog();
    }
  },
  props: {
    actors: { type: Array, required: true },
    visible: { type: Boolean, required: true, default: false }
  },
  watch: {
    showDialog: function() {
      if (this.visible) this.onReset();
    }
  }
};
</script>
