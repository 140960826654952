<template>
  <v-card
    id="actor-card"
    class="mx-1 my-2 pa-1"
    :class="{ 'is-tracked': isTracked }"
  >
    <v-row dense @click.stop="expanded = !expanded">
      <v-col cols="10" sm="11">
        <TitleBlock
          :actor="actor"
          :chatFlag="chatFlag"
          :healthBar="healthBar"
        />
      </v-col>
      <v-col class="text-center" cols="2" sm="1">
        <v-icon v-show="expanded">{{ icons.iconCollapse }}</v-icon>
        <v-icon v-show="!expanded">{{ icons.iconExpand }}</v-icon>
      </v-col>
    </v-row>
    <div
      @click.stop="onOpenActor"
      :class="{ 'is-dead': isDead, 'is-first': isFirst }"
    >
      <v-card-title class="pa-1">
        {{ actor.actorName }}
        <v-spacer />
        <v-btn color="amber lighten-3" fab light small @click.stop="onSubmit">
          <v-icon>{{ icons.iconUpload }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="pa-2 pb-2">
        {{ actor.getString("classSummary") }}
        <v-btn color="info" icon small :href="url" target="_blank" v-show="url">
          <v-icon>{{ icons.iconWebLink }}</v-icon>
        </v-btn>
      </v-card-subtitle>
    </div>
    <v-card-text v-show="expanded">
      <!-- Summary -->
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconSummary }}</v-icon>
        </v-col>
        <v-col cols="3">
          <ValBlock label="AC" :value="actor.getValue('acNormal')" />
        </v-col>
        <v-col cols="4">
          <ValBlock
            label="Perception"
            :showSign="true"
            :value="actor.getValue('skillPerception')"
          />
        </v-col>
        <v-col cols="3" sm="4">
          <ValBlock
            label="Stealth"
            :showSign="true"
            :value="actor.getValue('skillStealth')"
          />
        </v-col>
      </v-row>
      <!-- Hit Points -->
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.healthIcon(form.hpNow) }}</v-icon>
        </v-col>
        <v-col cols="3">
          <ValBlock label="HP" :showCommas="true" :value="form.hpNow" />
        </v-col>
        <v-col class="text-center" cols="7" sm="8">
          <v-slider
            class="pt-4"
            color="green-darken-1"
            track-color="red accent-4"
            v-model.trim="form.hpNow"
            :append-icon="icons.iconIncrement"
            :prepend-icon="icons.iconDecrement"
            :max="actor.hpMax"
            :min="-10"
            :thumb-label="true"
            @click:append="onAdjustHP($event, +1)"
            @click:prepend="onAdjustHP($event, -1)"
          />
        </v-col>
      </v-row>
      <!-- Conditions -->
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconCondition }}</v-icon>
        </v-col>
        <v-col cols="10" sm="11">
          <v-combobox
            append-icon
            chips
            clearable
            deletable-chips
            label="Conditions"
            multiple
            v-model="form.conditions"
            :search-input.sync="search"
            @keyup.tab="updateTags"
            @paste="updateTags"
          >
            <template v-slot:selection="data">
              <v-chip
                close
                label
                outlined
                small
                v-bind="data.attrs"
                :close-icon="icons.iconClose"
                :color="getTagColor(data.item)"
                :input-value="data.selected"
                :key="data.item"
                :text-color="getTagColor(data.item)"
                @click:close="data.parent.selectItem(data.item)"
              >
                {{ getTagLabel(data.item) }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row align="center" dense>
        <!-- Readied Action -->
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconAttacks }}</v-icon>
        </v-col>
        <v-col cols="4" sm="5">
          <v-select
            label="Readied Action"
            v-model.trim="form.selectedAction"
            :disabled="actionItems.length < 2"
            :items="actionItems"
          ></v-select>
        </v-col>
        <!-- Attitude -->
        <v-col cols="2" sm="1" v-if="!actor.idUser">
          <v-icon>{{ icons.iconAttitude }}</v-icon>
        </v-col>
        <v-col cols="4" sm="5" v-if="!actor.idUser">
          <v-select
            label="Attitude"
            v-model.trim="form.selectedAttitude"
            :items="attitudes"
          ></v-select>
        </v-col>
      </v-row>
      <v-expansion-panels accordion flat>
        <!-- Spells -->
        <v-expansion-panel
          v-for="school in actor.spells"
          :key="school.spellClass"
        >
          <v-expansion-panel-header>
            <div>
              <v-icon class="mr-3">{{ icons.iconSpells }}</v-icon>
              <span class="subtitle-2">
                Spells&nbsp;&mdash;&nbsp;{{ school.spellClass }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ActorSpells
              :isDM="isDM"
              :actor="actor"
              :spellClass="school.spellClass"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Talents -->
        <v-expansion-panel v-if="hasTalents">
          <v-expansion-panel-header>
            <div>
              <v-icon class="mr-3">{{ icons.iconTalents }}</v-icon>
              <span class="subtitle-2">Abilities</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense flat v-if="hasTalents">
              <v-list-item
                dense
                v-for="(talent, index) in actor.talents"
                :key="index"
              >
                <TalentBlock
                  :index="index"
                  :isDM="isDM"
                  :talent="talent"
                  @update-talent="updateTalent"
                />
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions v-show="expanded">
      <v-btn class="mx-2" icon outlined @click.stop="onActorEdit($event)">
        <v-icon>{{ icons.iconEdit }}</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn class="mx-2" icon outlined @click.stop="onInit">
        <v-icon>{{ icons.iconInit }}</v-icon>
      </v-btn>
      <v-btn class="mx-2" icon outlined @click.stop="onStartTurn">
        <v-icon>{{ icons.iconActivate }}</v-icon>
      </v-btn>
      <v-btn class="mx-2" icon outlined @click.stop="onActorHeal">
        <v-icon>{{ icons.iconHeal }}</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn color="red" dark icon outlined @click.stop="onKillActor">
        <v-icon>{{ icons.iconDead }}</v-icon>
      </v-btn>
    </v-card-actions>
    <ActorEdit
      :actor="actor"
      :editTemplate="editTemplate"
      :visible="showActorEdit"
      v-on:close-actor-edit="showActorEdit = false"
    />
  </v-card>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import TalentBlock from "@/blocks/TalentBlock";
import TitleBlock from "@/blocks/TitleBlock";
import ValBlock from "@/blocks/ValBlock";
import ActorSpells from "@/components/ActorSpells";
import ActorEdit from "@/dialogs/ActorEdit";
import {
  ActorUpdateDTO,
  Attitudes,
  getConditionColor,
  getConditionText,
  NEUTRAL,
} from "@/models/actor";
import { linkToSRD } from "@/services";
import { isNotEmpty, isValidStr, percentage, rollD20 } from "@/store";

export default {
  name: "ActorCard",

  components: {
    ActorEdit,
    TalentBlock,
    TitleBlock,
    ValBlock,
    ActorSpells,
  },
  computed: {
    actions() {
      return this.actor.actions || [];
    },
    chatFlag() {
      return this.isDM && this.actor.getValue("chatFlag") == 1;
    },
    hasTalents() {
      return isNotEmpty(this.actor.talents);
    },
    healthBar() {
      return this.showHealth &&
        !this.expanded &&
        this.actor.hpNow < this.actor.hpMax
        ? percentage(this.actor.hpNow, this.actor.hpMax)
        : -1;
    },
    healthLevel() {
      return percentage(this.form.hpNow, this.actor.hpMax);
    },
    healthText() {
      return `of ${this.actor.hpMax}`;
    },
    isDead() {
      return this.actor.hpNow <= -10;
    },
    isFirst() {
      return this.actor.combatOrder == 1;
    },
    isTracked() {
      return this.actor.combatOrder > 0;
    },
    readiedAction() {
      const readyAction = this.actor.getValue("readyAction");
      return this.actions[readyAction] || "";
    },
    url() {
      return isValidStr(this.actor.url) ? linkToSRD(this.actor.url) : undefined;
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      actionItems: [],
      attitudes: Attitudes,
      editTemplate: false,
      expanded: false,
      icons: IconLib,
      search: "", //sync search
      showActorEdit: false,
      //  form fields
      form: {
        hpNow: 0,
        conditions: [],
        selectedAction: 0,
        selectedAttitude: "",
      },
    };
  },
  methods: {
    getTagColor(tag) {
      return getConditionColor(tag);
    },
    getTagLabel(tag) {
      return getConditionText(tag);
    },
    load() {
      const defaultAttitude = NEUTRAL;

      var items = [];

      if (this.actions) {
        this.actions.forEach(function (act, idx) {
          items.push({ text: act.description, value: idx });
        });
      }

      if (this.actor) {
        this.form.hpNow = this.actor.hpNow;
        this.form.conditions = this.actor.conditions || [];
        this.form.selectedAction = this.actor.getValue("readyAction");
        this.form.selectedAttitude =
          this.actor.getString("attitude") || defaultAttitude;
      } else {
        this.form.hpNow = 0;
        this.form.conditions = [];
        this.form.selectedAction = 0;
        this.form.selectedAttitude = defaultAttitude;
      }

      this.actionItems = items;
    },
    onActorEdit(e) {
      this.editTemplate = e.shiftKey;
      this.showActorEdit = this.isDM;
    },
    onActorHeal() {
      this.$store.dispatch("actor/heal", this.actor.id);
    },
    onAdjustHP(e, delta) {
      if (e.shiftKey) delta *= 10;

      let hitPoints = this.form.hpNow + delta;
      if (hitPoints < -10) hitPoints = -10;
      if (hitPoints > this.actor.hpMax) hitPoints = this.actor.hpMax;

      this.form.hpNow = hitPoints;
    },
    onInit() {
      const dto = {
        id: this.actor.id,
        initRoll: rollD20(this.actor.getValue("initiative")),
      };

      this.$store.dispatch("actor/init", dto);
      this.expanded = false;
    },
    onKillActor() {
      const dto = new ActorUpdateDTO(this.actor.id, -10);
      // console.log("ActorCard::onKillActor() => %o", dto);
      this.$store.dispatch("actor/update", dto);
    },
    onOpenActor() {
      this.$store.dispatch("actor/select", this.actor.id);
      this.$store.commit("setViewTab", "tab-hero");
    },
    onStartTurn() {
      this.$store.dispatch("actor/makeActive", this.actor.id);
    },
    onSubmit() {
      const dto = new ActorUpdateDTO(
        this.actor.id,
        this.form.hpNow,
        this.form.selectedAction,
        this.form.selectedAttitude,
        this.form.conditions,
        this.actor.spells,
        this.actor.talents
      );

      // console.log("ActorCard::onSubmit() => %o", dto);
      this.$store.dispatch("actor/update", dto).then(() => {
        this.expanded = false;
      });
    },
    updateTags() {
      this.$nextTick(() => {
        this.select.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },
    updateTalent(dto) {
      const talent = this.actor.talents[dto.index];

      if (talent) {
        // console.log("updateTalent() %s -> %s", talent.valNow, dto.valNow);
        talent.valNow = dto.valNow;
      }
    },
  },
  props: {
    actor: { type: Object, required: true },
    isDM: { type: Boolean, required: false, default: false },
    showHealth: { type: Boolean, required: false, default: false },
  },
  watch: {
    actor: function () {
      this.load();
    },
  },
};
</script>

<style scoped>
.is-tracked {
  border-left-color: gainsboro;
  border-left-style: solid;
  border-left-width: 8px;
}
</style>
