import { MersenneTwister } from "./mersenneTwister";
import { isValidStr } from "@/store";
//
//  Grammar:
//
//  Expression  ::= Term |
//                  Expression '+' Term |
//                  Expression '-' Term |
//                  E
//  Term        ::= Factor |
//                  Term '*' Factor |
//                  Term '/' Factor
//  Factor      ::= Number |
//                  '+' Factor |//                  '-' Factor |
//                  Factor 'd' DieFace |
//                  '(' Expression ')'
//  DieFace     ::= Factor |
//                  '%'
//
export const rpgDice = (function() {
  "use strict";

  const _twister = new MersenneTwister(19880723);

  let _index = 0;
  let _input;
  let _isCritical = false;
  let _isFumble = false;
  let _lastIndex;
  let _rolls = [];

  const TT = {
    // no more data
    EPSILON: 0,
    // Number
    NUMBER: 1,
    // '('
    PAREN_OPEN: 2,
    //  ')'
    PAREN_CLOSE: 3,

    // '+'
    OP_ADD: 10,
    // '-'
    OP_SUB: 11,
    //  'd'
    OP_DIE: 12,
    //  '/'
    OP_DIV: 13,
    //  '*'
    OP_MUL: 14,
    //  '%'
    OP_PERCENT: 15
  };

  // ------------------------------------------------------------------------
  // PUBLIC METHODS
  // ------------------------------------------------------------------------

  function roll(input) {
    let result = NaN;

    try {
      _isCritical = false;
      _isFumble = false;
      _rolls = [];

      if (isValidStr(input)) {
        _index = 0;
        _input = input.trim();
        _lastIndex = input.length;
      } else throw "No Input";

      result = parseExpression();
      // console.log("roll(%s) => %d %o", input, result, _rolls);
    } catch (err) {
      console.log("rpgDice::roll() FAILED => %o", err);

      return {
        isValid: false,
        errorMessage: err || "Unknown error"
      };
    }

    return {
      isCritical: _isCritical,
      isFumble: _isFumble,
      isValid: true,
      rolls: _rolls,
      total: result
    };
  }

  function rollD20() {
    return Math.floor(_twister.rnd() * 20) + 1;
  }

  // ------------------------------------------------------------------------
  // IMPLEMENTATION
  // ------------------------------------------------------------------------

  function advance() {
    while (_index < _lastIndex) {
      const ch = _input.charAt(_index);

      if (ch == " " || ch == "\t" || ch == "\r" || ch == "\n") {
        _index++; // consume the character
      } else {
        return true;
      }
    }

    return false;
  }

  function isDigit(c) {
    return c >= "0" && c <= "9";
  }

  function parseDieFace() {
    // Special case to convert "d%" to "d100"
    return peekToken(TT.OP_PERCENT) ? 100 : parseFactor();
  }

  function parseExpression() {
    let result = parseTerm();

    while (_index < _lastIndex) {
      if (peekToken(TT.OP_ADD)) {
        result += parseTerm();
      } else if (peekToken(TT.OP_SUB)) {
        result -= parseTerm();
      } else break;
    }

    return result;
  }

  function parseFactor() {
    let result = NaN;

    if (peekToken(TT.OP_ADD)) {
      return parseFactor(); // unary plus
    }

    if (peekToken(TT.OP_SUB)) {
      return -parseFactor(); // unary minus
    }

    if (peekToken(TT.NUMBER)) {
      result = parseNumber();
    } else if (peekToken(TT.PAREN_OPEN)) {
      result = parseExpression();
      if (!peekToken(TT.PAREN_CLOSE)) throw "No closing ')'";
    }

    if (peekToken(TT.OP_DIE)) {
      result = rollDice(result, parseDieFace());
    }

    if (isNaN(result)) {
      const token = _input.substring(_index, Math.min(16, _lastIndex - _index));
      throw `Unexpected token: '${token}'`;
    }

    return result;
  }

  function parseNumber() {
    const start = _index;
    let allowDecimal = true;

    while (_index < _lastIndex) {
      const ch = _input.charAt(_index);

      if (isDigit(ch)) {
        _index++;
      } else if (ch == "." && allowDecimal) {
        _index++;
        allowDecimal = false;
      } else break;
    }

    return _index > start
      ? parseFloat(_input.substr(start, _index - start))
      : NaN;
  }

  function parseTerm() {
    let result = parseFactor();

    while (_index < _lastIndex) {
      if (peekToken(TT.OP_MUL)) {
        result *= parseFactor();
      } else if (peekToken(TT.OP_DIV)) {
        const divisor = parseFactor();
        if (divisor == 0) throw "Divide by zero.";
        result /= divisor;
      } else break;
    }

    return result;
  }

  function peekToken(tt) {
    if (!advance()) return tt === TT.EPSILON;

    const ch = _input.charAt(_index);
    let match = false;

    switch (tt) {
      case TT.NUMBER:
        match = isDigit(ch);
        break;
      case TT.PAREN_OPEN:
        match = ch === "(";
        break;
      case TT.PAREN_CLOSE:
        match = ch === ")";
        break;
      case TT.OP_ADD:
        match = ch === "+";
        break;
      case TT.OP_DIV:
        match = ch === "/";
        break;
      case TT.OP_DIE:
        match = ch === "d" || ch === "D";
        break;
      case TT.OP_MUL:
        match = ch === "*";
        break;
      case TT.OP_PERCENT:
        match = ch === "%";
        break;
      case TT.OP_SUB:
        match = ch === "-";
        break;

      default:
        return false;
    }

    // go ahead and consume single-character tokens
    if (match && tt != TT.NUMBER) _index++;

    return match;
  }

  function rollDice(count, faces) {
    if (isNaN(faces) || faces < 2) throw "Invalid die size";
    // default to '1' if count isn't explicity set.
    if (isNaN(count) || count < 1) count = 1;

    let result = 0;

    // Special case for rolling a single d20 -- check for natural 1's or 20's
    if (count === 1 && faces === 20) {
      result = _twister.roll(faces);

      _rolls.push(result);

      _isCritical = result == 20;
      _isFumble = result == 1;
    } else {
      for (var idx = 0; idx < count; idx++) {
        const roll = _twister.roll(faces);
        _rolls.push(roll);
        result += roll;
      }
    }

    // console.log("rollDice(%d, %d) => %d %o", count, faces, result, _rolls);

    return result;
  }

  return {
    // methods
    roll: roll,
    rollD20: rollD20
  };
})();
