<template>
  <v-row id="title-block" dense>
    <!-- Heroes -->
    <v-col cols="8" v-if="playerName">
      <v-row align="center" dense>
        <v-icon class="mx-2" color="amber" v-if="isOwned">
          {{ icons.iconOwner }}
        </v-icon>
        <v-icon class="mx-2" v-else-if="isFamiliar">
          {{ icons.iconFamiliar }}
        </v-icon>
        <v-icon class="mx-2" color="light-blue" v-else-if="chatFlag">
          {{ icons.iconChatFlag }}
        </v-icon>
        <v-icon class="mx-2" v-else>{{ icons.iconPlayer }}</v-icon>
        <span class="subtitle-2">
          {{ playerName }}
        </span>
      </v-row>
    </v-col>
    <!-- Foes -->
    <v-col cols="8" v-else-if="isHostile">
      <v-row align="center" dense>
        <v-icon class="mx-2" color="red">{{ icons.iconHostile }}</v-icon>
        <span class="red--text headline" v-show="jewel > 0">#{{ jewel }}</span>
      </v-row>
    </v-col>
    <!-- Neutral & NPCs -->
    <v-col cols="8" v-else>
      <v-row align="center" dense>
        <v-icon class="mx-2" v-if="actor.unique">{{ icons.iconNPC }}</v-icon>
        <v-icon class="mx-2" v-else>{{ icons.iconNeutral }}</v-icon>
        <span class="headline" v-show="jewel > 0">#{{ jewel }}</span>
      </v-row>
    </v-col>
    <v-col cols="4" v-if="healthBar >= 0">
      <v-progress-linear
        class="mx-2"
        color="green darken-1"
        height="12"
        :background-color="barColor"
        :value="healthBar"
      />
    </v-col>
  </v-row>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { isHostileActor } from "@/models/actor";

export default {
  name: "TitleBlock",
  computed: {
    barColor() {
      if (this.actor.hpNow > 0) return "red";

      return this.actor.hpNow > -10 ? "red darken-4" : "black";
    },
    currentUser() {
      return this.$store.getters["user/current"];
    },
    isFamiliar() {
      return  this.actor && this.actor.isFamiliar;
    },
    isHostile() {
      return isHostileActor(this.actor);
    },
    isOwned() {
      return (
        this.actor.idUser === this.currentUser.id ||
        this.actor.mapStrings["idMaster"] === this.currentUser.id
      );
    },
    jewel() {
      return this.actor.mapValues["jewel"] || 0;
    },
    playerName() {
      return this.actor.mapStrings["playerName"] || "";
    },
  },
  data: () => ({
    icons: IconLib,
  }),
  props: {
    actor: { type: Object, required: true },
    chatFlag: { type: Boolean, required: false, default: false },
    healthBar: { type: Number, required: false, default: -1 },
  },
};
</script>
