import axios from "axios";
import { codexUrl } from "@/services";
import apiCall from "@/services";
import { isValidStr } from "@/store";

class UserService {
  login(user) {
    console.log(`UserService::login(${user.username})`);

    return axios.post(codexUrl + "/user/login", user).then((response) => {
      if (response.status === 200) {
        // Valid login
        return Promise.resolve(response.data);
      } else {
        // Invalid login
        const error = isValidStr(response.data.message)
          ? new Error("Network Error")
          : new Error("Login Failed");

        return Promise.reject(error);
      }
    });
  }

  update(dto) {
    return apiCall("put", `/user/update`, dto);
  }
}

export default new UserService();
