<template>
  <v-form id="actor-notes" v-if="actor.unique" @submit.prevent="onSubmit">
    <v-textarea
      counter="0"
      full-width
      outlined
      placeholder="Dear Diary..."
      v-model.trim="text"
      :rules="rules"
    />
    <v-toolbar dense flat>
      <v-btn small text @click.stop="load"> Undo </v-btn>
      <v-spacer />
      <v-btn color="primary" small type="submit"> Save </v-btn>
    </v-toolbar>
  </v-form>
  <NothingBlock v-else />
</template>

<script>
import NothingBlock from "@/blocks/NothingBlock";
import { MAX_TEXT_LEN } from "@/models/actor";

export default {
  name: "ActorNotes",

  components: {
    NothingBlock,
  },
  created() {
    this.load();
  },
  data: () => ({
    idNote: null,
    rules: [
      (v) => v.length <= MAX_TEXT_LEN || `Max ${MAX_TEXT_LEN} characters`,
    ],
    text: "",
  }),
  methods: {
    load() {
      this.$store
        .dispatch("actor/loadNotes", this.actor.id)
        .then((dto) => {
          // console.log("ActorNotes::load() => %o", dto);
          this.idNote = dto.id;
          this.text = dto.text || "";
        })
        .catch(() => (this.text = ""));
    },
    onSubmit() {
      const data = {
        id: this.idNote,
        idActor: this.actor.id,
        text: this.text,
      };
      // console.log("ActorNotes::onSubmit() => %o", data);
      this.$store.dispatch("actor/updateNotes", data);
    },
  },
  props: {
    actor: { type: Object, required: true },
  },
  watch: {
    actor: function () {
      this.load();
    },
  },
};
</script>
