<template>
  <v-card
    id="tracker-card"
    class="mx-1 my-2 pa-1"
    dense
    :flat="isFirst"
    :class="{
      'is-first-card': isFirst,
      'is-hostile': isHostile,
      'is-owned': isOwned,
    }"
  >
    <v-row align="center" dense @click.stop="expanded = !expanded">
      <v-col cols="10" sm="11">
        <TitleBlock :actor="actor" :healthBar="healthLevel" />
      </v-col>
      <v-col class="text-center" cols="2" sm="1" v-if="!isFirst">
        <v-icon v-show="expanded">{{ icons.iconCollapse }}</v-icon>
        <v-icon v-show="!expanded">{{ icons.iconExpand }}</v-icon>
      </v-col>
    </v-row>
    <div @click.stop="onOpenActor" :class="{ 'is-dead': isDead }">
      <v-card-title>
        {{ actor.actorName }}
      </v-card-title>
      <v-card-subtitle v-show="!isHostile">
        {{ actor.getString("classSummary") }}
      </v-card-subtitle>
    </div>
    <v-card-text v-show="showCard">
      <v-row dense>
        <HealthBlock
          class="my-2"
          :hpNow="actor.hpNow"
          :hpMax="actor.hpMax"
          :showValues="showDetails"
        />
      </v-row>
      <v-row dense v-if="hasConditions">
        <ConditionBlock :conditions="actor.conditions" />
      </v-row>
      <v-row dense>
        <ActionBlock
          v-if="readiedAction"
          v-on:roll-damage="onRollDamage"
          v-on:roll-to-hit="onRollToHit"
          :action="readiedAction"
          :expandable="showDetails"
          :rollType="rollType"
          :rollValue="rollValue"
        />
      </v-row>
      <v-row align="center" dense v-if="isDM">
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconArmorClass }}</v-icon>
        </v-col>
        <v-col cols="3">
          <ValBlock label="AC" :value="actor.getValue('acNormal')" />
        </v-col>
        <v-col cols="4">
          <ValBlock
            label="Flatfooted"
            :muted="true"
            :value="actor.getValue('acFlatFooted')"
          />
        </v-col>
        <v-col cols="3">
          <ValBlock
            label="Touch"
            :muted="true"
            :value="actor.getValue('acTouch')"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-show="isDM && showCard">
      <v-btn
        class="mx-2"
        color="amber lighten-3"
        fab
        light
        small
        v-if="isFirst"
        @click.stop="onEndTurn"
      >
        <v-icon>{{ icons.iconEndTurn }}</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn color="amber lighten-3" fab light small @click.stop="onMoveUp">
        <v-icon>{{ icons.iconMoveUp }}</v-icon>
      </v-btn>
      <v-btn color="amber lighten-3" fab light small @click.stop="onMoveDown">
        <v-icon>{{ icons.iconMoveDown }}</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn color="red" dark fab small @click.stop="onRemove">
        <v-icon>{{ icons.iconDelay }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import ActionBlock from "@/blocks/ActionBlock";
import ConditionBlock from "@/blocks/ConditionBlock";
import TitleBlock from "@/blocks/TitleBlock";
import HealthBlock from "@/blocks/HealthBlock";
import ValBlock from "@/blocks/ValBlock";
import { isHostileActor } from "@/models/actor";
import { isNotEmpty, isValidStr, percentage } from "@/store";

export default {
  name: "TrackerCard",
  components: {
    ActionBlock,
    ConditionBlock,
    TitleBlock,
    HealthBlock,
    ValBlock,
  },
  computed: {
    actions() {
      return this.actor.actions || [];
    },
    hasConditions() {
      return this.isDM && isNotEmpty(this.actor.conditions);
    },
    healthLevel() {
      return this.expanded || this.isFirst
        ? -1
        : percentage(this.actor.hpNow, this.actor.hpMax);
    },
    isDead() {
      return this.actor.hpNow <= -10;
    },
    isFirst() {
      return this.actor.combatOrder == 1;
    },
    isHostile() {
      return isHostileActor(this.actor);
    },
    isOwned() {
      return (
        this.actor &&
        this.actor.idUser === this.$store.getters["user/current"].id
      );
    },
    readiedAction() {
      let readyAction = this.actor.getValue("readyAction");
      // minimal actors have only their readied action in the actions[] array.
      if (readyAction >= this.actions.length) readyAction = 0;

      return this.actions[readyAction] || {};
    },
    showCard() {
      return this.expanded || this.isFirst;
    },
    showDetails() {
      return this.isDM || this.isOwned;
    },
  },
  data() {
    return {
      expanded: this.isFirst,
      icons: IconLib,
      rollType: "info",
      rollValue: 0,
    };
  },
  methods: {
    extractRoll(input) {
      if (isValidStr(input)) {
        const posSlash = input.indexOf("/");
        return posSlash === -1 ? input : input.substr(0, posSlash);
      }

      return "";
    },
    moveActor(change) {
      if (Number.isInteger(change) && change !== 0) {
        const dto = {
          id: this.actor.id,
          delta: change,
        };
        this.$store.dispatch("actor/reorder", dto);
      }
    },
    onEndTurn() {
      this.$store.dispatch("actor/endTurn");
    },
    onMoveDown() {
      this.moveActor(+1);
    },
    onMoveUp() {
      this.moveActor(-1);
    },
    onOpenActor() {
      if (this.showDetails) {
        this.$store.dispatch("actor/select", this.actor.id);
        this.$store.commit("setViewTab", "tab-hero");
      }
    },
    onRemove() {
      this.$store.dispatch("actor/inactivate", this.actor.id);
    },
    onRollDamage() {
      if (!this.isDM) return;

      const input = this.extractRoll(this.readiedAction.damage);
      const roll = this.$store.state.diceBag.roll(input);

      // console.log("TrackerCard::onRollDamage( '%s' ) => %o", input, roll);

      if (roll.isValid) {
        this.rollValue = roll.total;
        this.rollType = "info";
      } else {
        this.rollValue = 0;
      }
    },
    onRollToHit() {
      if (!this.isDM) return;

      const input = this.extractRoll(this.readiedAction.attackBonus);
      const roll = this.$store.state.diceBag.roll("d20" + input);

      // console.log("TrackerCard::onRollToHit( '%s' ) => %o", input, roll);

      if (roll.isValid) {
        this.rollValue = roll.total;
        if (roll.isCritical) {
          this.rollType = "success";
        } else if (roll.isFumble) {
          this.rollType = "error";
        } else {
          this.rollType = "info";
        }
      } else {
        this.rollValue = 0;
      }
    },
  },
  props: {
    actor: { type: Object, required: true },
    isDM: { type: Boolean, required: false, default: false },
  },
};
</script>

<style scoped>
.is-first-card {
  border-color: silver;
  border-style: solid;
  border-width: 4px;
}
.is-hostile {
  border-color: firebrick;
  border-left-style: solid;
  border-left-width: 4px;
}
.is-owned {
  border-color: #ffc107;
  border-left-style: solid;
  border-left-width: 4px;
}
</style>
