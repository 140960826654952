<template>
  <v-row id="hero-view" dense v-if="hero">
    <v-toolbar class="elevation-0" dense v-if="isDM">
      <v-spacer />
      <v-btn
        class="mr-2"
        color="red"
        outlined
        small
        @click.stop="onActorDiscard"
      >
        <v-icon class="mdi-18px mr-2">{{ icons.iconRemove }}</v-icon>
        Discard
      </v-btn>
    </v-toolbar>
    <v-expansion-panels accordion multiple>
      <v-expansion-panel>
        <HeroCard :hero="hero" :isDM="isDM" />
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconDetails }}</v-icon>
            <span class="subtitle-2">Details</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorDetails :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="actions">
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconWeapons }}</v-icon>
            <span class="subtitle-2">Attacks</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorAttacks :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="hasTalents">
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconAbilities }}</v-icon>
            <span class="subtitle-2">Abilities</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorTalents :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconStats }}</v-icon>
            <span class="subtitle-2">Stats</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorStats :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconSkills }}</v-icon>
            <span class="subtitle-2">Skills</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorSkills :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-for="school in hero.spells" :key="school.spellClass">
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconSpells }}</v-icon>
            <span class="subtitle-2"
              >Spells&nbsp;&mdash;&nbsp;{{ school.spellClass }}</span
            >
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorSpells
            :isDM="false"
            :actor="hero"
            :spellClass="school.spellClass"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconFeats }}</v-icon>
            <span class="subtitle-2">Feats</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorFeats :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="familiar">
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconFamiliar }}</v-icon>
            <span class="subtitle-2">{{ familiar.actorName }}</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <FamiliarCard :familiar="familiar" :isDM="isDM" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="hero.unique">
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconGear }}</v-icon>
            <span class="subtitle-2">Gear</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorGear :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="hero.unique">
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconLoot }}</v-icon>
            <span class="subtitle-2">Loot</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorLoot :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="hero.unique">
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconNotes }}</v-icon>
            <span class="subtitle-2">Notes</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorNotes :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="canChat">
        <v-expansion-panel-header>
          <div>
            <v-icon class="mr-3">{{ icons.iconChat }}</v-icon>
            <span class="subtitle-2">DM&nbsp;Chat</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ActorChat :actor="hero" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <Confirm ref="confirm"></Confirm>
  </v-row>
  <v-row class="pt-4" v-else>
    <NothingBlock />
  </v-row>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import NothingBlock from "@/blocks/NothingBlock";
import ActorAttacks from "@/components/ActorAttacks";
import ActorChat from "@/components/ActorChat";
import ActorDetails from "@/components/ActorDetails";
import ActorFeats from "@/components/ActorFeats";
import ActorGear from "@/components/ActorGear";
import ActorLoot from "@/components/ActorLoot";
import ActorNotes from "@/components/ActorNotes";
import ActorSkills from "@/components/ActorSkills";
import ActorSpells from "@/components/ActorSpells";
import ActorStats from "@/components/ActorStats";
import ActorTalents from "@/components/ActorTalents";
import FamiliarCard from "@/components/FamiliarCard";
import HeroCard from "@/components/HeroCard";
import Confirm from "@/plugins/Confirm";
import { NEUTRAL } from "@/models/actor";
import { isNotEmpty, isValidStr } from "@/store";

export default {
  name: "HeroView",
  components: {
    NothingBlock,
    ActorAttacks,
    ActorChat,
    ActorDetails,
    ActorFeats,
    ActorGear,
    ActorLoot,
    ActorNotes,
    ActorSkills,
    ActorSpells,
    ActorStats,
    ActorTalents,
    Confirm,
    FamiliarCard,
    HeroCard,
  },
  computed: {
    actions() {
      return this.hero.actions || [];
    },
    attitude() {
      return this.hero.mapStrings["attitude"] || NEUTRAL;
    },
    canChat() {
      return (
        this.hero &&
        this.hero.unique &&
        this.hero.idUser &&
        !isValidStr(this.hero.mapStrings["idMaster"])
      );
    },
    hasTalents() {
      return isNotEmpty(this.hero.talents);
    },
  },
  data() {
    return {
      editTemplate: false,
      familiar: null,
      icons: IconLib,
    };
  },
  methods: {
    onActorDiscard() {
      if (this.hero.unique) {
        // Make it harder to discard unique actors.
        this.$refs.confirm
          .open(this.hero.actorName, "Discard this unique actor?", {
            color: "error",
          })
          .then((confirmed) => {
            if (confirmed) this.$store.dispatch("actor/remove", this.hero.id);
          });
      } else {
        this.$store.dispatch("actor/remove", this.hero.id);
      }
    },
    onRefresh() {
      this.familiar = null;

      if (this.hero) {
        const idFamiliar = this.hero.getString("idFamiliar");

        if (isValidStr(idFamiliar)) {
          this.$store
            .dispatch("actor/loadFamiliar", idFamiliar)
            .then((actor) => {
              this.familiar = actor;
            });
        }
      }
    },
  },
  props: {
    hero: { type: Object, required: false },
    isDM: { type: Boolean, required: false, default: false },
  },
  watch: {
    hero: function () {
      this.onRefresh();
    },
  },
};
</script>
