import { TextDTO } from "@/models/actor";
import { isValidStr } from "@/store";
import apiCall from "@/services";

class ActorService {
  //
  // GET
  //
  async loadActor(id) {
    return apiCall("get", `/actor/${id}`);
  }

  async loadAll() {
    return apiCall("get", "/actors");
  }

  async loadChat(id) {
    return apiCall("get", `/actor/${id}/chat`);
  }

  async loadGear(id) {
    return apiCall("get", `/actor/${id}/gear`);
  }

  async loadLoot(id) {
    return apiCall("get", `/actor/${id}/loot`);
  }

  async loadNotes(id) {
    return apiCall("get", `/actor/${id}/notes`);
  }

  async search(searchKey) {
    return isValidStr(searchKey)
      ? apiCall("get", `/actors/search/${searchKey}`)
      : Promise.reject("Invalid key");
  }
  //
  // PUT
  //
  async clone(dto) {
    // console.log("ActorService::clone(%o)", dto);
    return apiCall("put", `/actor/clone`, dto);
  }

  async create(dto) {
    // console.log("ActorService::create(%o)", dto);
    return apiCall("put", `/actor/create`, dto);
  }

  async endTurn() {
    return apiCall("put", "/actors/endTurn");
  }

  async heal(id) {
    // console.log("ActorService::heal(%s)", id);
    return apiCall("put", `/actor/${id}/heal`);
  }

  async healAll() {
    // console.log("ActorService::healAll()");
    return apiCall("put", `/actors/healAll`);
  }

  async inactivate(id) {
    // console.log("ActorService::inactivate(%s)", id);
    return apiCall("put", `/actor/${id}/inactivate`);
  }

  async inactivateAll() {
    // console.log("ActorService::inactivateAll()");
    return apiCall("put", `/actors/inactivateAll`);
  }

  async init(id, initRoll) {
    // console.log("ActorService::init(%s, %d)", id, initRoll);
    return apiCall("put", `/actor/${id}/init/${initRoll}`);
  }

  async loadRaw(id) {
    return apiCall("get", `/actor/${id}/raw`);
  }

  async makeActive(id) {
    // console.log("ActorService::makeActive(%s)", id);
    return apiCall("put", `/actor/${id}/activate`);
  }

  async reorder(id, delta) {
    return apiCall("put", `/actor/${id}/reorder/${delta}`);
  }

  async update(dto) {
    // console.log("ActorService::updateActor() => %o", dto);
    return apiCall("put", "/actor", dto);
  }

  async updateChat(data) {
    // console.log("ActorService::updateChat() => %o", data);
    return apiCall(
      "put",
      `/actor/${data.idActor}/chat`,
      new TextDTO(data.id, data.text)
    );
  }

  async updateGear(data) {
    // console.log("ActorService::updateGear() => %o", data);
    return apiCall(
      "put",
      `/actor/${data.idActor}/gear`,
      new TextDTO(data.id, data.text)
    );
  }

  async updateLoot(data) {
    // console.log("ActorService::updateLoot() => %o", data);
    return apiCall(
      "put",
      `/actor/${data.idActor}/loot`,
      new TextDTO(data.id, data.text)
    );
  }

  async updateNotes(data) {
    // console.log("ActorService::updateNotes() => %o", data);
    return apiCall(
      "put",
      `/actor/${data.idActor}/notes`,
      new TextDTO(data.id, data.text)
    );
  }

  async updateRaw(actor) {
    // console.log("ActorService::updateRaw() => %o", actor);
    return apiCall("put", `/actor/${actor.id}/raw`, actor);
  }

  //
  // DELETE
  //
  async remove(id) {
    return apiCall("delete", `/actor/${id}`);
  }

  async removeAll() {
    return apiCall("delete", "/actors");
  }
}

export default new ActorService();
