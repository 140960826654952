<template>
  <v-form id="actor-loot" v-if="actor.unique" @submit.prevent="onSubmit">
    <v-row class="px-1 py-3" dense>
      <v-col cols="3">
        <ValBlock label="Net Gold" :showCommas="true" :value="netWorth" />
      </v-col>
      <v-col cols="3">
        <ValBlock
          label="pp"
          :muted="true"
          :showCommas="true"
          :value="actor.getValue('coinPP')"
        />
      </v-col>
      <v-col cols="3">
        <ValBlock
          label="gp"
          :muted="true"
          :showCommas="true"
          :value="actor.getValue('coinGP')"
        />
      </v-col>
      <v-col cols="3">
        <ValBlock
          label="sp"
          :muted="true"
          :showCommas="true"
          :value="actor.getValue('coinSP')"
        />
      </v-col>
    </v-row>
    <v-textarea
      counter="0"
      full-width
      outlined
      placeholder="Oooh!  Shiny..."
      v-model.trim="text"
      :rules="rules"
    />
    <v-toolbar dense flat>
      <v-btn small text @click.stop="load">
        Undo
      </v-btn>
      <v-spacer />
      <v-btn color="primary" small type="submit">
        Save
      </v-btn>
    </v-toolbar>
  </v-form>
  <NothingBlock v-else />
</template>

<script>
import NothingBlock from "@/blocks/NothingBlock";
import ValBlock from "@/blocks/ValBlock";
import { MAX_TEXT_LEN } from "@/models/actor";

export default {
  name: "ActorLoot",

  components: {
    NothingBlock,
    ValBlock
  },
  computed: {
    netWorth() {
      const gp = this.actor.getValue("coinGP");
      const pp = this.actor.getValue("coinPP");
      const sp = this.actor.getValue("coinSP");

      return (pp * 10) + gp + Math.floor(sp / 10);
    }
  },
  created() {
    this.load();
  },
  data: () => ({
    idLoot: null,
    rules: [v => v.length <= MAX_TEXT_LEN || `Max ${MAX_TEXT_LEN} characters`],
    text: ""
  }),
  methods: {
    load() {
      this.$store
        .dispatch("actor/loadLoot", this.actor.id)
        .then(dto => {
          // console.log("ActorLoot::load() => %o", dto);
          this.idLoot = dto.id;
          this.text = dto.text || "";
        })
        .catch(() => (this.text = ""));
    },
    onSubmit() {
      const data = {
        id: this.idLoot,
        idActor: this.actor.id,
        text: this.text
      };
      // console.log("ActorLoot::onSubmit() => %o", data);
      this.$store.dispatch("actor/updateLoot", data);
    }
  },
  props: {
    actor: { type: Object, required: true }
  },
  watch: {
    actor: function() {
      this.load();
    }
  }
};
</script>
