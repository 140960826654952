<template>
  <v-list id="actor-talents" dense flat v-if="hasTalents">
    <v-list-item dense v-for="(talent, index) in actor.talents" :key="index">
      <TalentBlock :index="index" :talent="talent" />
    </v-list-item>
  </v-list>
  <NothingBlock v-else />
</template>

<script>
import { isNotEmpty } from "@/store";
import TalentBlock from "@/blocks/TalentBlock";
import NothingBlock from "@/blocks/NothingBlock";

export default {
  name: "ActorTalents",

  components: {
    NothingBlock,
    TalentBlock,
  },
  computed: {
    hasTalents() {
      return isNotEmpty(this.actor.talents);
    },
  },
  props: {
    actor: { type: Object, required: true },
    isDM: { type: Boolean, required: false, default: false },
  },
};
</script>
