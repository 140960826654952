<template>
  <v-list-item id="skill-block" dense>
    <v-col class="pa-1" cols="4" sm="5">
      <v-row no-gutters>
        <v-col cols="12">
          <span class="subtitle-1">{{ skill.title }}</span>
        </v-col>
        <v-col cols="12" v-if="skill.subtitle">
          <span class="font-italic subtitle-2">{{ skill.subtitle }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="pa-1" cols="3">
      <ValBlock label="Ranks" :muted="true" :value="skill.ranks" v-show="skill.ranks>0"/>
    </v-col>
    <v-col class="pa-1" cols="3">
      <ValBlock label="Modifier" :showSign="true" :value="skill.modifier" />
    </v-col>
    <v-col class="pa-1" cols="2" sm="1" v-if="url">
      <v-btn color="info" icon small target="_blank" :href="url">
        <v-icon class="mdi-18px">{{ icons.iconWebLink }}</v-icon>
      </v-btn>
    </v-col>
  </v-list-item>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { linkToSRD } from "@/services";
import { isValidStr } from "@/store";
import ValBlock from "@/blocks/ValBlock";

export default {
  name: "SkillBlock",
  components: {
    ValBlock
  },
  computed: {
    url() {
      return isValidStr(this.skill.url) ? linkToSRD(this.skill.url) : undefined;
    }
  },
  data: () => ({
    icons: IconLib
  }),
  props: {
    skill: { type: Object, required: true }
  }
};
</script>
