<template>
  <v-card class="hero-card pa-1" outlined>
    <TitleBlock :actor="hero" />
    <v-card-title class="pt-1">
      {{ hero.actorName }}
    </v-card-title>
    <v-card-subtitle class="pt-1">
      {{ hero.getString("classSummary") }}
    </v-card-subtitle>
    <v-card-text>
      <HealthBlock :hpNow="hero.hpNow" :hpMax="hero.hpMax" :showValues="true" />
      <ConditionBlock :conditions="hero.conditions" v-if="hasConditions" />
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconInit }}</v-icon>
        </v-col>
        <v-col cols="3">
          <ValBlock
            label="Init"
            :value="hero.getValue('initiative')"
            :showSign="true"
          />
        </v-col>
        <v-col cols="7" sm="8" v-show="speed">
          <ValBlock label="Speed" :muted="true" :value="speed" />
        </v-col>
      </v-row>
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconArmorClass }}</v-icon>
        </v-col>
        <v-col cols="3">
          <ValBlock label="AC" :value="hero.getValue('acNormal')" />
        </v-col>
        <v-col cols="4">
          <ValBlock
            label="Flatfooted"
            :muted="true"
            :value="hero.getValue('acFlatFooted')"
          />
        </v-col>
        <v-col cols="3">
          <ValBlock
            label="Touch"
            :muted="true"
            :value="hero.getValue('acTouch')"
          />
        </v-col>
      </v-row>
      <ActionBlock
        v-if="readiedAction"
        :action="readiedAction"
        :expandable="true"
      />
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconGrapple }}</v-icon>
        </v-col>
        <v-col cols="5">
          <ValBlock label="CMB" :value="hero.getString('cmb')" />
        </v-col>
        <v-col cols="5">
          <ValBlock label="CMD" :value="hero.getString('cmd')" />
        </v-col>
      </v-row>
      <v-row align="center" dense>
        <v-col cols="2" sm="1">
          <v-icon>{{ icons.iconSavingThrows }}</v-icon>
        </v-col>
        <v-col cols="3">
          <ValBlock label="Fortitude" :value="hero.getValue('saveFortitude')" />
        </v-col>
        <v-col cols="3" sm="4">
          <ValBlock label="Reflex" :value="hero.getValue('saveReflex')" />
        </v-col>
        <v-col cols="3">
          <ValBlock label="Will" :value="hero.getValue('saveWill')" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import ActionBlock from "@/blocks/ActionBlock";
import ConditionBlock from "@/blocks/ConditionBlock";
import TitleBlock from "@/blocks/TitleBlock";
import HealthBlock from "@/blocks/HealthBlock";
import ValBlock from "@/blocks/ValBlock";
import { isNotEmpty } from "@/store";

export default {
  name: "HeroCard",

  components: {
    ActionBlock,
    ConditionBlock,
    TitleBlock,
    HealthBlock,
    ValBlock,
  },
  computed: {
    hasConditions() {
      return isNotEmpty(this.hero.conditions);
    },
    readiedAction() {
      const readyAction = this.hero.getValue("readyAction");
      return this.hero.actions[readyAction] || "";
    },
    speed() {
      return this.hero.mapStrings["speed"];
    },
  },
  data: () => ({
    icons: IconLib,
  }),
  props: {
    hero: { type: Object, required: true },
    isDM: { type: Boolean, required: false, default: false },
  },
};
</script>
