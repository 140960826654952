import Vue from "vue";
import Vuex from "vuex";

import { actor } from "./actor.module";
import { user } from "./user.module";
import { rpgDice } from "@/assets/rpgdice";

Vue.use(Vuex);

export function clamp(value, valMin, valMax) {
  if (value >= valMin && value <= valMax) return value;

  return value <= valMin ? valMin : valMax;
}

export function compareArrays(lhs, rhs) {
  return (
    isValidArray(lhs) &&
    isValidArray(rhs) &&
    lhs.length === rhs.length &&
    lhs.every(function (value, index) {
      return value === rhs[index];
    })
  );
}

export function compareStrings(lhs, rhs) {
  return isValidStr(lhs) && isValidStr(rhs)
    ? lhs.localeCompare(rhs, undefined, { sensitivity: "accent" }) === 0
    : lhs === rhs;
}

export function formatInt(value, showCommas, showSign) {
  if (value === 0) return "0"; // "\u2014" (&mdash;)

  let str = value.toString();

  if (showCommas && (value < -999 || value > 999))
    str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (showSign && value > 0) str = "+" + str;

  return str;
}

export function isNotEmpty(array) {
  return isValidArray(array) && array.length > 0;
}

export function isValidArray(array) {
  return typeof array != "undefined" && array != null && array.length != null;
}

export function isValidObj(obj) {
  return typeof obj != "undefined" && obj != null;
}

export function isValidNum(num) {
  return !isNaN(num);
}

export function isValidStr(str) {
  return (typeof str === "string" || str instanceof String) && str.length > 0;
}

export function percentage(numerator, denominator) {
  if (numerator && numerator > 0 && denominator && denominator > 0) {
    return numerator < denominator
      ? Math.ceil((numerator * 100) / denominator)
      : 100;
  }

  return 0;
}

export function rollD20(bonus) {
  return Math.max(1, Math.ceil(Math.random() * 20) + (bonus || 0));
}

export default new Vuex.Store({
  state: {
    diceBag: rpgDice,
    diceBagVisible: false,
    version: "0.8 as of 01-Mar-2021",
    viewTab: 0,
  },
  actions: {
    toggleDiceBag: async function (ctx) {
      // console.log("toggleDiceBag() => %o", ctx.state.diceBagVisible);
      ctx.commit("setDiceBag", !ctx.state.diceBagVisible);
    },
    updateAll: async function (ctx) {
      // console.log("updateAll()");
      ctx.dispatch("actor/loadAll");
    },
  },
  getters: {
    showDiceBag: (state) => {
      return state.diceBagVisible;
    },
    viewTab: (state) => {
      return state.viewTab;
    },
  },
  modules: {
    actor,
    user,
  },
  mutations: {
    setDiceBag(state, visible) {
      state.diceBagVisible = visible;
      // console.log("state.showDiceBag = %o", visible);
    },
    setViewTab(state, tabRef) {
      if (tabRef == "tab-hero") {
        state.viewTab = 0;
      } else if (tabRef == "tab-tracker") {
        state.viewTab = 1;
      } else {
        state.viewTab = tabRef;
      }

      // console.log("state.viewTab = %o", state.viewTab);
    },
  },
});
