<template>
  <v-list id="actor-feats" class="pa-1" dense flat v-if="hasFeats">
    <FeatBlock v-for="feat in actor.feats" :feat="feat" :key="feat.key" />
  </v-list>
  <NothingBlock v-else />
</template>

<script>
import FeatBlock from "@/blocks/FeatBlock";
import NothingBlock from "@/blocks/NothingBlock";
import { isNotEmpty } from "@/store";

export default {
  name: "ActorFeats",

  components: {
    FeatBlock,
    NothingBlock
  },
  computed: {
    hasFeats() {
      return isNotEmpty(this.actor.feats);
    }
  },
  props: {
    actor: { type: Object, required: true },
    isDM: { type: Boolean, required: false, default: false }
  }
};
</script>
