<template>
  <v-container id="layout-double" class="pa-2" fluid>
    <v-row dense>
      <v-col cols="6">
        <LayoutSingle v-if="isDM" />
        <HeroView v-else :hero="hero" :isDM="isDM" />
      </v-col>
      <v-col cols="6">
        <ActorList v-if="isDM" />
        <Tracker v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LayoutSingle from "@/components/LayoutSingle";
import ActorList from "@/views/ActorList";
import HeroView from "@/views/HeroView";
import Tracker from "@/views/Tracker";

export default {
  name: "LayoutDouble",
  components: {
    LayoutSingle,
    ActorList,
    HeroView,
    Tracker,
  },
  computed: {
    hero() {
      return this.$store.getters["actor/current"];
    },
    isDM() {
      return this.$store.getters["user/isDM"];
    },
  },
};
</script>
