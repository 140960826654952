<template>
  <v-row id="health-block" align="center" no-gutters>
    <v-col cols="2" sm="1">
      <v-icon>{{ icons.healthIcon(hpNow) }}</v-icon>
    </v-col>
    <v-col cols="10" sm="11">
      <v-progress-linear
        background-color="red accent-4"
        color="green darken-1"
        dark
        height="28"
        :value="healthLevel"
      >
        <template :v-slot="value">
          <strong>{{ legend }}</strong>
        </template>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { percentage } from "@/store";

export default {
  name: "HealthBlock",

  computed: {
    healthLevel() {
      return percentage(this.hpNow, this.hpMax);
    },
    legend() {
      if (this.hpNow > 0) {
        return this.showValues ? `${this.hpNow} of ${this.hpMax}` : "";
      }
      if (this.hpNow == 0) return "UNCONSCIOUS";
      if (this.hpNow > -10) {
        return this.showValues ? `${this.hpNow} (DYING)` : "DYING";
      }

      return "DEAD";
    }
  },
  data() {
    return {
      icons: IconLib
    };
  },
  props: {
    hpMax: { type: Number, required: true, default: 0 },
    hpNow: { type: Number, required: true, default: 0 },
    showValues: { type: Boolean, required: false, default: false }
  }
};
</script>
