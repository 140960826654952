<template>
  <v-dialog id="user-update" max-width="512" persistent v-model="showDialog">
    <v-form @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>User&nbsp;Update</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" offset-sm="3">
              <v-text-field
                autocomplete="off"
                label="Password"
                name="oldSecret"
                required
                v-model.trim="$v.oldSecret.$model"
                :error-messages="errOldSecret"
                :prepend-icon="icons.iconPassword"
                :type="showPW ? 'text' : 'password'"
                @blur="$v.oldSecret.$touch()"
                @input="$v.oldSecret.$touch()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" offset-sm="3">
              <v-text-field
                autocomplete="off"
                label="New Password"
                name="newSecret"
                required
                v-model.trim="$v.newSecret.$model"
                :error-messages="errNewSecret"
                :prepend-icon="icons.iconPassword"
                :type="showPW ? 'text' : 'password'"
                @blur="$v.newSecret.$touch()"
                @input="$v.newSecret.$touch()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" offset-sm="3">
              <v-text-field
                autocomplete="off"
                label="Confirm Password"
                name="dupSecret"
                required
                v-model.trim="$v.dupSecret.$model"
                :error-messages="errDupSecret"
                :prepend-icon="icons.iconPassword"
                :type="showPW ? 'text' : 'password'"
                @blur="$v.dupSecret.$touch()"
                @input="$v.dupSecret.$touch()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-alert class="mx-2 my-3" type="error" v-if="error">
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click.stop="closeDialog">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn small v-if="showPW" @click.stop="showPW = false">
            <v-icon class="mr-2">{{ icons.iconShowPassword }}</v-icon>
            Hide Passwords
          </v-btn>
          <v-btn small v-else @click.stop="showPW = true">
            <v-icon class="mr-2">{{ icons.iconHidePassword }}</v-icon>
            Show Passwords
          </v-btn>
          <v-spacer />
          <v-btn color="primary" type="submit">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { isValidStr } from "@/store";
import { required } from "vuelidate/lib/validators";

class UserUpdateInfo {
  constructor(oldSecret, newSecret) {
    this.oldSecret = oldSecret || "";
    this.newSecret = newSecret || "";
  }
}

export default {
  name: "UserUpdate",

  computed: {
    currentUser() {
      return this.$store.getters["user/current"];
    },
    errOldSecret() {
      const errors = [];
      if (this.$v.oldSecret.$dirty) {
        !this.$v.oldSecret.required && errors.push("This field is required.");
      }
      return errors;
    },
    errNewSecret() {
      const errors = [];
      if (this.$v.newSecret.$dirty) {
        !this.$v.newSecret.required && errors.push("This field is required.");
      }
      return errors;
    },
    errDupSecret() {
      const errors = [];
      if (this.$v.dupSecret.$dirty) {
        !this.$v.dupSecret.required && errors.push("This field is required.");
      }
      return errors;
    },
    showDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  data() {
    return {
      error: "",
      icons: IconLib,
      showPW: false,
      // form fields
      oldSecret: "",
      newSecret: "",
      dupSecret: ""
    };
  },
  methods: {
    closeDialog: function() {
      this.$emit("close-user-update");
    },
    onReset() {
      this.error = "";
      this.oldSecret = "";
      this.newSecret = "";
      this.dupSecret = "";
    },
    onSubmit() {
      if (!isValidStr(this.newSecret) || this.newSecret !== this.dupSecret) {
        this.error = "New passwords do not match.";
      } else if (this.newSecret === this.oldSecret) {
        this.error = "New password must be different.";
      } else {
        const dto = new UserUpdateInfo(
          this.$v.oldSecret.$model,
          this.$v.newSecret.$model
        );

        console.log("UserUpdate::onSubmit() => %o", dto);

        this.error = "";
        this.$store.dispatch("user/update", dto);
        this.closeDialog();
      }
    }
  },
  props: {
    visible: { type: Boolean, required: true, default: false }
  },
  watch: {
    showDialog: function() {
      if (this.visible) this.onReset();
    }
  },
  validations: {
    oldSecret: { required },
    newSecret: { required },
    dupSecret: { required }
  }
};
</script>
