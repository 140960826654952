<template>
  <v-list-item id="stat-block" dense>
    <v-col class="pa-1" cols="4" sm="5">
      <v-row no-gutters>
        <v-col cols="12">
          <span class="subtitle-1">{{ stat.title }}</span>
        </v-col>
        <v-col cols="12">
          <span class="font-italic subtitle-2">{{ abbreviation }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="pa-1" cols="3">
      <ValBlock label="Score" :showSign="false" :value="stat.score" />
    </v-col>
    <v-col class="pa-1" cols="3">
      <ValBlock label="Modifier" :showSign="true" :value="stat.modifier" />
    </v-col>
    <v-col class="pa-1" cols="2" sm="1" v-if="url">
      <v-btn color="info" icon small target="_blank" :href="url">
        <v-icon class="mdi-18px">{{ icons.iconWebLink }}</v-icon>
      </v-btn>
    </v-col>
  </v-list-item>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { linkToSRD } from "@/services";
import { isValidStr } from "@/store";
import ValBlock from "@/blocks/ValBlock";

export default {
  name: "StatBlock",
  components: {
    ValBlock
  },
  computed: {
    abbreviation() {
      switch (this.stat.title) {
        case "Strength":
          return "STR";
        case "Dexterity":
          return "DEX";
        case "Intelligence":
          return "INT";
        case "Wisdom":
          return "WIS";
        case "Constitution":
          return "CON";
        case "Charisma":
          return "CHA";
      }

      return "";
    },
    url() {
      return isValidStr(this.stat.url) ? linkToSRD(this.stat.url) : undefined;
    }
  },
  data: () => ({
    icons: IconLib
  }),
  props: {
    stat: { type: Object, required: true }
  }
};
</script>
