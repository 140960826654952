<template>
  <v-dialog id="monster-summon" max-width="640" persistent v-model="showDialog">
    <v-card>
      <v-card-title> Summon&nbsp;Monster&hellip; </v-card-title>
      <v-card-subtitle>
        <v-toolbar class="elevation-0">
          <v-text-field
            clearable
            hide-details
            placeholder="Search by Name"
            single-line
            v-model.trim="form.searchKey"
            :prepend-icon="icons.iconSearch"
            @click:prepend="onSearch"
            @keydown.enter="onSearch"
          />
        </v-toolbar>
      </v-card-subtitle>
      <v-card-text>
        <v-row
          align="center"
          dense
          v-for="(info, index) in visiblePages"
          :key="index"
        >
          <v-col cols="4">
            <span class="mx-2 subtitle-1">{{ info.actor.actorName }}</span>
            <v-icon icon small v-show="info.actor.unique">
              {{ icons.iconUnique }}
            </v-icon>
          </v-col>
          <v-col cols="4">
            <SpinnerBlock
              label="Count"
              :minVal="0"
              :maxVal="info.maxCount"
              :value="info.actorCount"
              v-on:value-change="info.actorCount = $event"
            />
          </v-col>
          <v-col cols="2" offset="2">
            <v-chip
              label
              light
              @click="onTrackClick(info)"
              :color="trackColor(info)"
              :disabled="info.actorCount <= 0"
            >
              <v-icon class="mr-2">{{ icons.iconTracker }}</v-icon>
              Track
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click.stop="closeDialog"> Cancel </v-btn>
        <v-spacer />
        <v-pagination
          class="pb-2"
          v-model="page"
          v-show="pages > 1"
          :length="pages"
          :total-visible="7"
        ></v-pagination>
        <v-spacer />
        <v-btn color="primary" type="submit" @click.stop="onSubmit"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { ActorInitDTO, HOSTILE } from "@/models/actor";
import SpinnerBlock from "@/blocks/SpinnerBlock";
import { isNotEmpty, isValidStr } from "@/store";

class InitInfo {
  constructor(actor) {
    this.actor = actor;
    this.actorCount = 0;
    this.attitude = actor.mapStrings["attitude"] || HOSTILE;
    this.autoInit = false;
    this.maxCount = actor.unique ? 1 : 10;
  }
}

export default {
  name: "MonsterSummon",

  components: {
    SpinnerBlock,
  },
  computed: {
    itemCount() {
      return isNotEmpty(this.form.info) ? this.form.info.length : 0;
    },
    pages() {
      return Math.ceil(this.itemCount / this.perPage);
    },
    showDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    visiblePages() {
      return this.form.info.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },
  data() {
    return {
      icons: IconLib,
      page: 1,
      perPage: 4,
      //  form fields
      form: {
        info: [],
        searchKey: "",
      },
    };
  },
  methods: {
    closeDialog: function () {
      this.$emit("close-monster-summon");
    },
    fillForm(actors) {
      // console.log("MonsterSummon::fillForm() => %o", actors);
      this.form.info = [];
      this.page = 1;
      if (actors && actors.length) {
        actors.forEach((actor) => {
          this.form.info.push(new InitInfo(actor));
        });
      }
    },
    onAdjust(info, delta) {
      info.actorCount = Math.max(
        0,
        Math.min(info.maxCount, info.actorCount + delta)
      );
    },
    onReset() {
      // console.log("MonsterSummon::onReset()");
      this.form.info = [];
      this.form.searchKey = "";
      this.page = 1;
    },
    onSearch() {
      if (!isValidStr(this.form.searchKey)) return;
      // console.log("MonsterSummon::onSearch(%s)", this.form.searchKey);
      this.$store.dispatch("actor/search", this.form.searchKey).then(
        (results) => {
          this.fillForm(results);
        },
        (err) => {
          console.log("actor/search FAILED => ", err);
        }
      );
    },
    onSubmit() {
      // console.log("MonsterSummon::onSubmit() => %o", this.form.info);
      this.form.info
        .filter((i) => i.actorCount > 0)
        .forEach((info) => {
          const dto = new ActorInitDTO(
            info.actor.id,
            info.actorCount,
            info.autoInit,
            info.attitude
          );

          this.$store.dispatch("actor/clone", dto);
        });

      this.closeDialog();
    },
    onTrackClick(info) {
      info.autoInit = !info.autoInit;
    },
    trackColor(info) {
      return info.autoInit ? "amber lighten-2" : "white";
    },
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
  },
  watch: {
    showDialog: function () {
      if (this.visible) this.onReset();
    },
  },
};
</script>
