<template>
  <v-list-item dense>
    <v-col class="pa-1" cols="10" sm="11">
      <v-row>
        <span class="subtitle-1">{{ feat.title }}</span>
        <span class="font-italic grey--text ml-2 subtitle-1">{{ this.stackCount }}</span>
      </v-row>
      <v-row v-if="feat.subtitle">
        <span class="font-italic body-2">{{ feat.subtitle }}</span>
      </v-row>
    </v-col>
    <v-col class="pa-1" cols="2" sm="1" v-if="url">
      <v-btn color="info" icon small :href="url" target="_blank">
        <v-icon>{{ icons.iconWebLink }}</v-icon>
      </v-btn>
    </v-col>
  </v-list-item>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { linkToSRD } from "@/services";
import { isValidStr } from "@/store";

export default {
  name: "FeatBlock",
  computed: {
    stackCount() {
      const count = this.feat.stacked + 1;
      return count > 1 ? "×" + count : "";
    },
    url() {
      return isValidStr(this.feat.url) ? linkToSRD(this.feat.url) : undefined;
    }
  },
  data: () => ({
    icons: IconLib
  }),
  props: {
    feat: { type: Object, required: true }
  }
};
</script>
