<template>
  <v-form id="actor-chat" v-if="actor.unique" @submit.prevent="onSubmit">
    <v-textarea
      counter="0"
      full-width
      outlined
      placeholder="Pssst..."
      v-model.trim="text"
      :rules="rules"
    >
    <template slot="append-outer">
      <v-icon color="red" @click.stop="onClear">{{ icons.iconClose }}</v-icon>
    </template>
    </v-textarea>
    <v-toolbar dense flat>
      <v-btn small text @click.stop="onRefresh">
        Refresh
      </v-btn>
      <v-spacer />
      <v-btn color="primary" small type="submit">
        Send
      </v-btn>
    </v-toolbar>
  </v-form>
  <NothingBlock v-else />
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import NothingBlock from "@/blocks/NothingBlock";
import { MAX_TEXT_LEN } from "@/models/actor";

export default {
  name: "ActorChat",

  components: {
    NothingBlock
  },
  created() {
    this.onRefresh();
  },
  data: () => ({
    icons: IconLib,
    idChat: null,
    rules: [
      v =>
        v == null ||
        v.length <= MAX_TEXT_LEN ||
        `Max ${MAX_TEXT_LEN} characters`
    ],
    text: ""
  }),
  methods: {
    onClear() {
      console.log("ActorChat::onClear()");
      this.text = "";
    },
    onRefresh() {
      this.$store
        .dispatch("actor/loadChat", this.actor.id)
        .then(dto => {
          // console.log("ActorChat::onRefresh() => %o", dto);
          this.idChat = dto.id;
          this.text = dto.text || "";
        })
        .catch(() => (this.text = ""));
    },
    onSubmit() {
      const data = {
        id: this.idChat,
        idActor: this.actor.id,
        text: this.text || ""
      };
      // console.log("ActorChat::onSubmit() => %o", data);
      this.$store.dispatch("actor/updateChat", data);
    }
  },
  props: {
    actor: { type: Object, required: true }
  },
  watch: {
    actor: function() {
      this.onRefresh();
    }
  }
};
</script>
