<template>
  <v-container id="action-block">
    <v-row align="center" dense @click="onExpand">
      <v-col cols="2" sm="1">
        <v-icon>{{ iconData }}</v-icon>
      </v-col>
      <v-col cols="10" sm="11">
        <span class="title" :class="{ 'is-readied': readied }">
          {{ action.description }}
        </span>
      </v-col>
    </v-row>
    <v-row dense v-if="expanded">
      <v-col cols="1">
        <v-badge
          tile
          v-show="rollValue"
          :color="rollType"
          :content="rollValue"
          :offset-x="-8"
          :offset-y="+32"
          :value="rollValue"
        />
      </v-col>
      <v-col cols="5" v-on:click="$emit('roll-to-hit')">
        <ValBlock label="To Hit" :value="action.attackBonus" />
      </v-col>
      <v-col cols="6" v-on:click="$emit('roll-damage')">
        <ValBlock label="Damage" :value="action.damage" />
      </v-col>
    </v-row>
    <v-row dense v-if="expanded">
      <v-col cols="5" offset="1">
        <ValBlock label="Critical" :value="action.critical" />
      </v-col>
      <v-col cols="6">
        <ValBlock
          label="Effects"
          :value="action.effects"
          v-if="action.effects"
        />
      </v-col>
    </v-row>
    <v-row dense v-if="expanded">
      <v-col cols="5" offset="1">
        <ValBlock label="Range" :value="action.range" v-if="action.range" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import { compareStrings, isValidStr } from "@/store";
import ValBlock from "@/blocks/ValBlock";

export default {
  name: "ActionBlock",
  components: {
    ValBlock,
  },
  computed: {
    iconData() {
      if (!isValidStr(this.action.range)) return IconLib.iconMelee;

      return compareStrings(this.action.range, "touch")
        ? IconLib.iconTouch
        : IconLib.iconRanged;
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    onExpand() {
      this.expanded = this.expandable && !this.expanded;
    },
  },
  props: {
    action: { type: Object, required: true },
    expandable: { type: Boolean, required: false, default: false },
    readied: { type: Boolean, required: false, default: false },
    rollType: { type: String, required: false, default: "info" },
    rollValue: { type: Number, required: false, default: 0 },
  },
};
</script>

<style scoped>
.is-readied {
  font-weight: bolder;
}
</style>
