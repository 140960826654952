<template>
  <v-row id="talent-block" align="center" dense>
    <v-col class="pa-1" cols="7">
      <v-row no-gutters>
        <v-col cols="12">
          <span class="subtitle-1">{{ talent.title }}</span>
        </v-col>
        <v-col cols="12" v-if="talent.subtitle">
          <span class="font-italic body-2">{{ talent.subtitle }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="5" v-if="isDM">
      <SpinnerBlock
        :minVal="0"
        :maxVal="talent.valMax"
        :showRange="true"
        :value="talent.valNow"
        v-on:value-change="updateTalent($event)"
      />
    </v-col>
    <v-col cols="5" v-else>
      <v-row align="center" dense>
        <v-col cols="10" sm="11">
          <ValBlock
            :label="labelText"
            :showZero="false"
            :value="talent.valNow"
          />
        </v-col>
        <v-col cols="2" sm="1" v-if="url">
          <v-btn color="info" icon small target="_blank" :href="url">
            <v-icon class="mdi-18px">{{ icons.iconWebLink }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { IconLib } from "@/assets/iconLib";
import SpinnerBlock from "@/blocks/SpinnerBlock";
import ValBlock from "@/blocks/ValBlock";
import { linkToSRD } from "@/services";
import { isValidStr } from "@/store";

export default {
  name: "TalentBlock",
  components: {
    SpinnerBlock,
    ValBlock,
  },
  computed: {
    labelText() {
      return `of ${this.talent.valMax}`;
    },
    url() {
      return isValidStr(this.talent.url)
        ? linkToSRD(this.talent.url)
        : undefined;
    },
  },
  data: () => ({
    icons: IconLib,
  }),
  methods: {
    updateTalent(value) {
      const dto = {
        index: this.index,
        valNow: value,
      };

      this.$emit("update-talent", dto);
    },
  },
  props: {
    index: { type: Number, required: true, default: 0 },
    talent: { type: Object, required: true },
    isDM: { type: Boolean, required: false, default: false },
  },
};
</script>
